var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('TheListingDetailsCard',_vm._b({scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Announcements")]},proxy:true},{key:"empty",fn:function(){return [_vm._v("No announcements")]},proxy:true},{key:"body",fn:function(){return [_vm._t("announcements",function(){return _vm._l((_vm.announcements),function({ announcement, announcementType, href },idx){return _c('TheAnnouncement',_vm._b({key:`announcement-${idx}`,staticClass:"py-1"},'TheAnnouncement',{
                    announcement,
                    announcementType,
                    href,
                    id: idx,
                },false),[(!_vm.announcements?.length)?_c('p',{staticClass:"has-text-grey-light"},[_vm._v(" No announcements ")]):_vm._e()])})},null,{ getIconColorByAnnouncementType: _vm.getIconColorByAnnouncementType })]},proxy:true}],null,true)},'TheListingDetailsCard',{
    showEmptyText: !_vm.announcements.length,
    ..._vm.$attrs
},false))
}
var staticRenderFns = []

export { render, staticRenderFns }