var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('TheListingDetailsAnnouncements',_vm._b({scopedSlots:_vm._u([{key:"announcements",fn:function({ getIconColorByAnnouncementType }){return [(_vm.isEditingMode)?_c('div',{key:_vm.announcementsKey},[_vm._l((_vm.uneditableAnnouncements),function({ announcementType, announcement, href },idx){return _c('TheAnnouncement',_vm._b({key:`announcement-uneditable-${idx}`,staticClass:"mb-3"},'TheAnnouncement',{ announcement, announcementType, href },false))}),_vm._l((_vm.updatedAnnouncements),function({ announcement, announcementType, href },idx){return _c('div',{key:`announcement-${idx}`},[(!href)?_c('div',{staticClass:"flex-row align-start"},[_c('EditableDropdown',_vm._b({on:{"input":function($event){_vm.updateAnnouncement('announcementType', $event.announcementType, idx); _vm.emitAnnouncements();}},scopedSlots:_vm._u([{key:"dropdownTrigger",fn:function({ active }){return [_c('AppButton',{attrs:{"icon-right":active ? 'chevron-down' : 'chevron-right'}},[_c('b-icon',{staticClass:"mr-2",class:getIconColorByAnnouncementType(announcementType),attrs:{"icon":"alert-circle"}})],1)]}},{key:"dropdownItem",fn:function({ option }){return [_c('div',{staticClass:"flex-row"},[_c('b-icon',{staticClass:"mr-2",class:getIconColorByAnnouncementType(option),attrs:{"icon":"alert-circle"}}),_c('p',[_vm._v(_vm._s(option))])],1)]}}],null,true)},'EditableDropdown',{
                            value: announcementType,
                            label: 'announcementType',
                            options: ['positive', 'negative', 'neutral'],
                            appendToBody: true,
                            expanded: true,
                        },false)),_c('EditableField',_vm._b({staticClass:"ml-2 mt-2",on:{"input":function($event){_vm.updateAnnouncement('announcement', $event, idx); _vm.emitAnnouncements();},"select":function($event){_vm.updateAnnouncement('announcement', $event, idx); _vm.emitAnnouncements();},"delete":function($event){_vm.deleteAnnouncement(idx); _vm.emitAnnouncements();},"toggleEditField":function($event){return _vm.updateEditingAnnouncementIdx($event, idx)}}},'EditableField',{
                            id: `announcement-${idx}`,
                            editingFieldInitial: _vm.editingAnnouncementIdx == idx,
                            clickToEdit: Boolean(announcement),
                            value: announcement,
                            canDelete: true,
                            customInputHandler: true,
                            inputType: 'autocomplete',
                            data: _vm.announcementPredictions,
                            loading: _vm.loadingAnnouncementPredictions == idx,
                            leaveEditModeOnBlur: true,
                            showDeleteWhileNotEditing: true,
                            appendToBody: true,
                        },false))],1):_vm._e()])}),_c('div',{staticClass:"flex-row justify-center"},[_c('AppButton',_vm._b({staticClass:"no-border",on:{"click":_vm.addAnnouncement}},'AppButton',{
                        label: '+ Add an announcement',
                        type: 'is-primary-dark',
                        outlined: true,
                        disabled: !_vm.canAddAnnouncement,
                    },false))],1)],2):_vm._e()]}}])},'TheListingDetailsAnnouncements',{
        ..._vm.$props,
        ..._vm.$attrs,
        showEmptyText: !_vm.isEditingMode && !_vm.updatedAnnouncements.length,
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }