
import { computed, defineComponent, PropType, Ref, ref } from 'vue';
import { useFetchListing, useUserRole } from '../composables';
import { SRPListing } from '../types';
import { useRoute } from 'vue-router/composables';
import store from '@/vuex';

import AppListingDetailsAdmin from './AppListingDetailsAdmin.vue'
import AppListingDetailsBuyer from './AppListingDetailsBuyer.vue';
import AppListingDetailsLoading from '../components/AppListingDetailsLoading.vue';
import AppListingDetailsMarketplace from '../components/AppListingDetailsMarketplace.vue';
import AppListingDetailsInspector from './AppListingDetailsInspector.vue';
import AppListingDetailsSeller from './AppListingDetailsSeller.vue';

export default defineComponent({
    name: 'AppListingDetailsBase',
    props: {
        vehicleListingId: {
            type: Number,
            required: false,
        },
        listing: {
            type: Object as PropType<SRPListing>,
            required: false,
        },
    },
    components: {
        AppListingDetailsAdmin,
        AppListingDetailsBuyer,
        AppListingDetailsLoading,
        AppListingDetailsMarketplace,
        AppListingDetailsInspector,
        AppListingDetailsSeller,
    },
    emits: ['watching'],
    setup(props, { emit, attrs }) {
        // get vehicleListingId from url
        const vehicleListingIdUpdated: Ref<number> = ref(props.listing?.id ?? props.vehicleListingId);
        let route;
        try {
            route = useRoute();
            if (route?.params?.id) {
                vehicleListingIdUpdated.value = parseInt(route.params.id);
            }
            if (route?.params?.vehicleListingId) {
                vehicleListingIdUpdated.value = parseInt(route.params.vehicleListingId);
            }
        } catch {}

        // user roles
        const { isUserAdmin, isUserBuyer, isUserDsr, isUserInspector } = useUserRole(store);

        // get vehicle listing
        const { vehicleListing, loadingVehicleListing } = useFetchListing({ 
            vehicleListingId: vehicleListingIdUpdated.value, 
            listingProp: props.listing,
        });
        const marketplaceListingId = computed(() => vehicleListing.value?.marketplaceListingId);

        return {
            route,
            vehicleListing,
            loadingVehicleListing,
            marketplaceListingId,
            isUserAdmin,
            isUserBuyer,
            isUserDsr,
            isUserInspector,
        }
    },
});
