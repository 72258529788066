import { APIConfig, InspectionUpdate, InspectionUpdateType, InspectionUpdateVehicleBasics } from "@/types";
import { applyAPIConfigOnError, applyAPIConfigOnSuccess, formatCelebrateValidationError, openErrorDialog, openSubmitInspectionErrorDialog, openToast } from "@/utils";
import { PUT } from ".";


export async function submitInspection(vehicleListingId: number, config: APIConfig={}) {
    await PUT(`/inspection/submit/${vehicleListingId}`)
      .then(res => {
        applyAPIConfigOnSuccess(res.data, config);
        return res.data;
      }).catch(error => {
        applyAPIConfigOnError(error, config);
        openSubmitInspectionErrorDialog(error?.response?.data);
      });
}


export async function updateInspectionData(vehicleListingId: number, updates: InspectionUpdate, updateType: InspectionUpdateType, marketplaceListingId?: number) {
    const savedUpdates = await PUT(`/inspection/${updateType}/${vehicleListingId}`, {
      ...updates,
      marketplaceListingId: updateType == 'saveBasicInfo' ? marketplaceListingId : undefined
    }).then(res => {
        return res.data;
      }).catch(error => {
        throw error;
      });
    return savedUpdates;
}

export async function requestInspectionByInventoryId(inventoryId: number, config: APIConfig={}) {
    return await PUT(`/inspection/requestInspection`, { inventoryId })
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            const celebrateValidationMessage = formatCelebrateValidationError(error);
            openErrorDialog({
                title: 'Failed to request inspection',
                message: celebrateValidationMessage ?? `We encountered an error while requesting an inspection for inventory ID ${inventoryId}.`,
                error,
            });
        });
}

export async function cancelInspectionRequestByInventoryId(inventoryId: number, config: APIConfig={}) {
    return await PUT(`/inspection/cancelInspectionRequest`, { inventoryId })
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openErrorDialog({
                title: 'Cancellation failed',
                message: `We encountered an error while cancelling the inspection request for inventory ID ${inventoryId}`,
                error,
            });
        });
}

export async function getAnnouncementPrediction(searchText: string, config: APIConfig={}) {
  return await PUT(`/inspection/announcementSearch`, { searchText })
    .then(res => {
      applyAPIConfigOnSuccess(res.data, config);
      return res.data;
    }).catch(error => {
      applyAPIConfigOnError(error, config);
      openToast('is-danger', `Announcement prediction failed for input: ${searchText}`);
    });
}

export async function getFeaturePrediction(searchText: string, config: APIConfig={}) {
  return await PUT(`/inspection/featureSearch`, { searchText })
    .then(res => {
      applyAPIConfigOnSuccess(res.data, config);
      return res.data;
    }).catch(error => {
      applyAPIConfigOnError(error, config);
      openToast('is-danger', `Feature prediction failed for input: ${searchText}`);
    });
}
