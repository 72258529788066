import { GET, PUT, getAdditionalPricingDetailsByVehicleListingId, updateInspectionData } from '@/api';
import { AdditionalPricingDTO, APIConfig, BidHistoryBid, DeliveryStatus, HighestBidRefSnapshot, ImageDTO, InspectionSectionName, InspectionUpdateBookoutOptions, InspectionUpdateType, MarketDataDTO, SRPListing, TitleStatus, VehicleListingAnnouncement, VehiclePhotosDTO, VehicleStatus } from '@/types';
import { applyAPIConfigOnError, applyAPIConfigOnSuccess, browserBaseUrl, convertBBFeaturesToBookoutOptions, convertVehiclePhotosDtoToImageDtoArray, copyText, formatCelebrateValidationError, formatEditVdpErrorMessage, goToVDP, isMarketplaceStatus, isSecondChanceStatus, makePascalCase, openErrorDialog, openModal } from '@/utils';
import { computed, getCurrentInstance, onBeforeMount, onMounted, ref, Ref, watch } from 'vue';
import VueRouter from 'vue-router';
import { useRouter } from 'vue-router/composables';
import store from '@/vuex';
import { useBreakpoint } from './breakpoint';
import { UseFetchOptions, useFetch, UseFetchOptionsWithoutInitialData, useCancelToken } from './fetch';
import { useFetchOrder, useGetArbitrationDetails } from './order';
import { InspectionDTO } from '@/types/InpectionDTO';
import { BBVehicleFeature } from '@/types/bbVehicleFeature';
import { BModalComponent } from 'buefy/types/components';
import { useUserRole } from './user';
import { debounce, filter, meanBy, startCase } from 'lodash';

import AppListingDetailsModal from '../components/AppListingDetailsModal.vue';

export function useOpenVdpModal() {
    const router = useRouter();
    const { windowWidth } = useBreakpoint();
    const instance = getCurrentInstance();

    const openedModal: Ref<BModalComponent | undefined> = ref(undefined);

    /** This is needed because TheListingMarketDataTooltip has a click event that we don't want to open the VDP modal,
     * but we still need that click event to propagate due to the click event listeners in TheListingMarketDataTooltip, which 
     * watch for outside clicks in order to close the tooltip.
     */
    function openVdpModalWithClickValidation(event: any, vdpModalProps: { 
        listing: SRPListing, 
        onClose?: ({ toggleWatchlist }: { toggleWatchlist?: boolean }) => void,
    }) {
        if (event?.srcElement?.classList) {
            let classList = Array.from(event.srcElement.classList);
            // ignore events if 'stop-propagation' is included in clicked element class
            if (classList.includes('stop-propagation')) {
                return;
            }

            // expand the market-data cards if market-data-tooltip was clicked
            if (classList.includes('market-data-tooltip')) {
                openVdpModal({
                    expandedCards: ['marketData'],
                    ...vdpModalProps, 
                });
                return;
            }
        }
        openVdpModal(vdpModalProps);
    }

    const debouncedOpenVdpModalWithClickValidation = debounce(openVdpModalWithClickValidation, 300);

    function openVdpModal({ listing, expandedCards, onClose }: {
        listing: SRPListing,
        expandedCards?: string[],
        onClose?: ({ toggleWatchlist }: { toggleWatchlist?: boolean }) => void,
    }) {
        let toggleWatchlist = false;
        if (windowWidth.value > 760) {
            openedModal.value = openModal({
                parent: instance?.proxy,
                component: AppListingDetailsModal,
                props: { 
                    listing, 
                    router,
                    expandedCards,
                },
                events: {
                    close: () => {
                        openedModal.value = undefined;
                        if (onClose) {
                            onClose({ toggleWatchlist });
                        }
                    },
                    watching: (event: boolean) => {
                        toggleWatchlist = event;
                    },
                },
                customClass: 'carmigo-modal vdp-modal',
            });
        } else {
            let vdpRoute = router.resolve({
                name: 'Auction Details',
                params: {
                    id: listing.id.toString(),
                    activeTab: '0',
                }
            });
            window.open(vdpRoute.href, '_blank');
            return;
        }
    }

    return {
        openVdpModal,
        debouncedOpenVdpModalWithClickValidation,
        openVdpModalWithClickValidation,
        openedModal,
    }
}

export function useHandleStatusChange({ rdbListener, statusTrigger, onStatusChange }: {
    rdbListener: Ref<any>,
    statusTrigger?: VehicleStatus,
    onStatusChange: (newStatus?: VehicleStatus, oldStatus?: VehicleStatus) => void,
}) {
    watch(() => rdbListener.value, async ({ status: newStatus }, { status: oldStatus }) => {
        if (!statusTrigger) {
            onStatusChange(newStatus, oldStatus);
            return;
        }
        if (newStatus == statusTrigger && oldStatus !== statusTrigger) {
            onStatusChange();
        }
    });
}

export function useVdpModal({ vehicleListingId, router }: {
    vehicleListingId: number,
    router: VueRouter
}) {
    function shareListing() {
        copyText(`${browserBaseUrl()}/#/auctions/${vehicleListingId}/0`);
    }

    function expandListing() {
        goToVDP(vehicleListingId);
    }

    return {
        shareListing,
        expandListing,
    }
}

export function useFetchListing({ vehicleListingId, listingProp }: { 
    vehicleListingId: number,
    listingProp?: SRPListing,
}) {
    const vehicleListing: Ref<SRPListing | undefined> = ref(listingProp) as Ref<SRPListing | undefined>;
    const loadingVehicleListing: Ref<boolean> = ref(false);
    if (listingProp?.marketplaceListingId) {
        vehicleListing.value = {
            ...listingProp,
            marketplaceListingId: isMarketplaceStatus(listingProp.status) || isSecondChanceStatus(listingProp.status) ? listingProp.marketplaceListingId : undefined,
        }
    }

    if (!listingProp) {
        fetchListing();
    }

    async function fetchListing() {
        loadingVehicleListing.value = true;
        await PUT(`/vehicles/getActiveListings`, {
            modifiers: {
                filters: [
                    {
                        property: 'vehicleListing.id',
                        comparator: '=',
                        values: vehicleListingId
                    }
                ]
            }
        }).then(res => {
            loadingVehicleListing.value = false;
            if (res.data.listings?.length) {
                let listing = res.data.listings[0];
                vehicleListing.value = {
                    ...listing,
                    marketplaceListingId: (isMarketplaceStatus(listing.status) || isSecondChanceStatus(listing.status) || listing.status == 'Registered') ? listing.marketplaceListingId : undefined,
                };
            } else {
                openErrorDialog({
                    title: `Vehicle not found`,
                    message: `We didn't find any results for a listing with ID ${vehicleListingId}. If this is a mistake, please contact our team.`,
                });
            }
        }).catch(error => {
            loadingVehicleListing.value = false;
            openErrorDialog({
                title: `Failed to fetch vehicle`,
                message: `We encountered an error while fetching details for vehicle listing ${vehicleListingId}. If the problem persists, please contact support.`,
                error,
            });
        });
    }

    return {
        vehicleListing,
        loadingVehicleListing,
    }
}

export function useSellerVDP({ vehicleListingId }: { vehicleListingId: number }) {
    const { vehiclePhotos, loadingVehiclePhotos, vehiclePhotosDTO } = useFetchVehiclePhotos(vehicleListingId);
    const { announcements, loadingAnnouncements } = useFetchAnnouncements(vehicleListingId);

    return {
        vehiclePhotos,
        loadingVehiclePhotos,
        vehiclePhotosDTO,
        announcements,
        loadingAnnouncements,
    }
}

export function useInspectorVDP({ vehicleListingId }: { vehicleListingId: number }) {
    const { vehiclePhotos, loadingVehiclePhotos, vehiclePhotosDTO } = useFetchVehiclePhotos(vehicleListingId);
    const { announcements, loadingAnnouncements } = useFetchAnnouncements(vehicleListingId);
    const { bookoutOptions, loadingBookoutOptions } = useFetchBookoutOptions(vehicleListingId);
    const { tireTread, loadingTireTread } = useFetchTireTread(vehicleListingId);
    const { titleStatus, loadingTitleStatus } = useFetchTitleStatus(vehicleListingId);
    const { sellerReason, loadingSellerReason } = useFetchSellerReason(vehicleListingId);
    const { additionalPricingDetails, loadingAdditionalPricingDetails } = useFetchAdditionalPricingDetails(vehicleListingId);

    return {
        vehiclePhotos,
        loadingVehiclePhotos,
        vehiclePhotosDTO,
        announcements,
        loadingAnnouncements,
        bookoutOptions,
        loadingBookoutOptions,
        tireTread,
        loadingTireTread,
        titleStatus,
        loadingTitleStatus,
        sellerReason,
        loadingSellerReason,
        additionalPricingDetails,
        loadingAdditionalPricingDetails,
    }
}

export function useAuctionVDP({ vehicleListingId, vehicleDetails }: { 
    vehicleListingId: number,
    vehicleDetails: {
        year: string,
        make: string,
        model: string,
        trim: string,
        vin: string,
    },
}) {
    const buyerZip = computed(() => store.getters.getTransportationZip ?? '');

    const { vehiclePhotos, loadingVehiclePhotos, vehiclePhotosDTO } = useFetchVehiclePhotos(vehicleListingId);
    const { announcements, loadingAnnouncements } = useFetchAnnouncements(vehicleListingId);
    const { bookoutOptions, loadingBookoutOptions } = useFetchBookoutOptions(vehicleListingId);
    const { bidHistory, loadingBidHistory, fetchBidHistoryOnRdbChange } = useFetchBidHistory(vehicleListingId);
    const { tireTread, loadingTireTread } = useFetchTireTread(vehicleListingId);
    const { order, loadingOrder, fetchOrder } = useFetchOrder(vehicleListingId);
    const { titleStatus, loadingTitleStatus } = useFetchTitleStatus(vehicleListingId);
    const { deliveryStatus, loadingDeliveryStatus } = useFetchDeliveryStatus(vehicleListingId);
    const { marketData, loadingMarketData, averageMarketDataCost } = useFetchListingMarketData(buyerZip, vehicleDetails);

    const purchasedVehicle = computed(() => order.value?.buyerPersonId == parseInt(store.state.user.profile.id));

    return {
        vehiclePhotos,
        loadingVehiclePhotos,
        vehiclePhotosDTO,
        announcements,
        loadingAnnouncements,
        bookoutOptions,
        loadingBookoutOptions,
        bidHistory,
        loadingBidHistory,
        fetchBidHistoryOnRdbChange,
        tireTread,
        loadingTireTread,
        order,
        loadingOrder,
        titleStatus,
        loadingTitleStatus,
        deliveryStatus,
        loadingDeliveryStatus,
        marketData,
        loadingMarketData,
        averageMarketDataCost,
        purchasedVehicle,
        fetchOrder,
    }
}

export function useMarketplaceVDP({ vehicleListingId, marketplaceListingId, vehicleDetails }: {
    vehicleListingId: number,
    marketplaceListingId: number,
    vehicleDetails: {
        year: string,
        make: string,
        model: string,
        trim: string,
        vin: string,
    }
}) {
    const {
        vehiclePhotos,
        loadingVehiclePhotos,
        announcements,
        loadingAnnouncements,
        bookoutOptions,
        loadingBookoutOptions,
        tireTread,
        loadingTireTread,
        order,
        loadingOrder,
        fetchOrder,
        titleStatus,
        loadingTitleStatus,
        deliveryStatus,
        loadingDeliveryStatus,
        purchasedVehicle,
        marketData,
        loadingMarketData,
        averageMarketDataCost,
    } = useAuctionVDP({ 
        vehicleListingId,
        vehicleDetails,
    });

    const { marketplaceOffers, loadingMarketplaceOffers } = useFetchMarketplaceOffers(marketplaceListingId);
    const { arbitrationDetails } = useGetArbitrationDetails(vehicleListingId);

    return {
        vehiclePhotos,
        loadingVehiclePhotos,
        announcements,
        loadingAnnouncements,
        bookoutOptions,
        loadingBookoutOptions,
        tireTread,
        loadingTireTread,
        marketplaceOffers,
        loadingMarketplaceOffers,
        order,
        loadingOrder,
        marketData,
        loadingMarketData,
        averageMarketDataCost,
        fetchOrder,
        titleStatus,
        loadingTitleStatus,
        deliveryStatus,
        loadingDeliveryStatus,
        purchasedVehicle,
        arbitrationDetails,
    }
}

export function useFetchVehiclePhotos(vehicleListingId: number) {
    const vehiclePhotosDTO: Ref<VehiclePhotosDTO | undefined> = ref(undefined);
    const { data: vehiclePhotos, loading: loadingVehiclePhotos } = useFetch<ImageDTO[]>(`/vehicles/photos/${vehicleListingId}`, {
        onError: (error) => {
            openErrorDialog({
                title: 'Failed to fetch vehicle photos',
                message: `We encountered an error while fetching the vehicle photos for vehicle ${vehicleListingId}`,
                error,
            });
        },
        onSuccess: (response, data) => {
            vehiclePhotosDTO.value = response as unknown as VehiclePhotosDTO;
            data.value = convertVehiclePhotosDtoToImageDtoArray(response as unknown as VehiclePhotosDTO);
        },
    });

    return {
        vehiclePhotos,
        loadingVehiclePhotos,
        vehiclePhotosDTO,
    }
}

export function useFetchAnnouncements(vehicleListingId: number, options: UseFetchOptions<VehicleListingAnnouncement[]> = { immediate: true }) {
    const {
        data: announcements,
        loading: loadingAnnouncements,
        execute: fetchAnnouncements,
    } = useFetch<VehicleListingAnnouncement[]>(`/inspection/getAnnouncements/${vehicleListingId}`, {
        immediate: options.immediate,
        onError(error) {
            openErrorDialog({
                title: 'Failed to fetch announcements',
                message: `We encountered an error while fetching announcements for vehicle listing ${vehicleListingId}. If the problem persists, please contact support`,
                error,
            });
        },
    });

    return {
        announcements,
        loadingAnnouncements,
        fetchAnnouncements,
    };
}

export function useFetchListingMarketData(zip: Ref<string>, vehicleDetails: {
    year: string,
    make: string,
    model: string,
    trim: string,
    vin: string,
}) {
    const { cancelToken, createNewCancelToken, cancelPreviousRequest } = useCancelToken();
    const marketData: Ref<MarketDataDTO | undefined> = ref(undefined);
    const loadingMarketData = ref(false);
    
    createNewCancelToken();
    getMarketData(zip.value, { cancelToken: cancelToken.value?.token });

    watch(zip, () => {
        if (zip?.value) {
            cancelPreviousRequest();
            getMarketData(zip.value, { cancelToken: cancelToken.value?.token });
        }
    });


    async function getMarketData(zipcode: string, config: APIConfig={}) {
    loadingMarketData.value = true;
        await PUT(`/marketData/getMarketData`, {
            ...vehicleDetails,
            zip: zipcode,
        }, { cancelToken: config?.cancelToken })
            .then(res => {
                loadingMarketData.value = false;
                marketData.value = res.data;
            }).catch(error => {
                loadingMarketData.value = false;
            });
    }

    const averageMarketDataCost = computed(() => meanBy(marketData.value?.soldVehicles, v => v.price));
    const numRetailTransactions = computed(() => marketData.value?.soldVehicles?.length ?? 0);

    return {
        marketData,
        loadingMarketData,
        averageMarketDataCost,
        numRetailTransactions,
        getMarketData,
    }
}

export function useFetchBookoutOptions(vehicleListingId: number) {
    const { data: bookoutOptions, loading: loadingBookoutOptions } = useFetch<InspectionUpdateBookoutOptions>(`/vehicles/getBookoutOptions/${vehicleListingId}`, {
        onSuccess: (bbFeatureDTO, data) => {
            data.value = convertBBFeaturesToBookoutOptions(bbFeatureDTO as unknown as Array<BBVehicleFeature & { price: number }>);
        },
        onError: (error) => {
            openErrorDialog({
                title: 'Failed to fetch bookout options',
                message: `We encountered an error while fetching bookout options for vehicle listing ${vehicleListingId}. If the problem persists, contact support.`,
                error,
            });
        },
    });

    return {
        bookoutOptions,
        loadingBookoutOptions,
    };
}

export function useFetchBidHistory(vehicleListingId: number, apiConfig: APIConfig={}) {
    const { isUserAdmin, isUserDsr } = useUserRole(store);

    let getBidHistory = (isUserAdmin.value || isUserDsr.value) ? 'getBidHistoryByVehicleListingIdForAdmin' : 'getBidHistoryByVehicleListingIdForPublic'
    const { data: bidHistory, loading: loadingBidHistory } = useFetch<BidHistoryBid[]>(`/vehicles/${vehicleListingId}/${getBidHistory}`, {
        onError: (error) => {
            applyAPIConfigOnError(error, apiConfig);
            openErrorDialog({
                title: 'Failed to fetch bookout options',
                message: `We encountered an error while fetching bookout options for vehicle listing ${vehicleListingId}. If the problem persists, contact support.`,
                error,
            });
        },
        ...apiConfig,
    });

    async function fetchBidHistoryOnRdbChange({ newSnapshotValue }: HighestBidRefSnapshot) {
        if (newSnapshotValue?.bidHistory?.length !== bidHistory.value?.length) {
            loadingBidHistory.value = true;
            await GET(`/vehicles/${vehicleListingId}/${getBidHistory}`)
                .then(res => {
                    loadingBidHistory.value = false;
                    bidHistory.value = res.data
                });
        }
    }

    return {
        bidHistory,
        loadingBidHistory,
        fetchBidHistoryOnRdbChange,
    };
}

export function useFetchMarketplaceOffers(marketplaceListingId: number) {
    const { data: marketplaceOffers, loading: loadingMarketplaceOffers } = useFetch(`marketplace/getMarketplaceListing/marketplaceListingOffers/${marketplaceListingId}`, {
        onError: (error) => {
            openErrorDialog({
                title: 'Failed to fetch marketplace offers',
                message: `We encountered an error while fetching marketplace offers for marketplace listing ${marketplaceListingId}. If the problem persists, contact support.`,
                error,
            });
        }
    })

    return {
        marketplaceOffers,
        loadingMarketplaceOffers,
    }
}

export function useFetchVehicleSpecs(vehicleListingId: number) {
    const { data: vehicleSpecs, loading: loadingVehicleSpecs } = useFetch(`/vehicle/getVehicleSpecs/${vehicleListingId}`);

    return {
        vehicleSpecs,
        loadingVehicleSpecs,
    }
}

export function useFetchTireTread(vehicleListingId: number) {
    const { data: tireTread, loading: loadingTireTread } = useFetch<InspectionDTO>(`/vehicles/getTireTreadDepth/${vehicleListingId}`);

    return {
        tireTread,
        loadingTireTread,
    }
}

export function useFetchTitleStatus(vehicleListingId: number, options?: UseFetchOptionsWithoutInitialData<TitleStatus>) {
    const { data: titleStatus, loading: loadingTitleStatus } = useFetch<TitleStatus>(`/vehicles/titleStatus/${vehicleListingId}`, options);

    return {
        titleStatus,
        loadingTitleStatus,
    }
}

export function useFetchDeliveryStatus(vehicleListingId: number, options?: UseFetchOptionsWithoutInitialData<DeliveryStatus>) {
    const { data: deliveryStatus , loading: loadingDeliveryStatus } = useFetch(`/vehicles/deliveryStatus/${vehicleListingId}`, options);
    
    return {
        deliveryStatus,
        loadingDeliveryStatus,
    }
}

export function useFetchSellerReason(vehicleListingId: number, options?: UseFetchOptionsWithoutInitialData<string | undefined>) {
    const { data: sellerReason, loading: loadingSellerReason } = useFetch<string | undefined>(`/vehicles/${vehicleListingId}/sellerReason`, {
        onSuccess: (res, data) => {
            sellerReason.value = typeof res == 'string' ? res : undefined;
        }
    });
    return {
        sellerReason,
        loadingSellerReason,
    }
}

export function useFetchAdditionalPricingDetails(vehicleListingId: number) {
    const loadingAdditionalPricingDetails = ref(true);
    const additionalPricingDetails: Ref<AdditionalPricingDTO | undefined> = ref(undefined);

    getAdditionalPricingDetailsByVehicleListingId(vehicleListingId, {
        onSuccess: (response) => {
            additionalPricingDetails.value = response;
            loadingAdditionalPricingDetails.value = false;
        },
        onError: () => {
            loadingAdditionalPricingDetails.value = false;
        }
    });

    return {
        additionalPricingDetails,
        loadingAdditionalPricingDetails,
    }
}

export function useIncrementViewCount(vehicleListingId: number) {
    const { isUserBuyer } = useUserRole(store);
    onMounted(async() => {
        if (isUserBuyer.value) {
            await PUT(`/vehicles/${vehicleListingId}/incrementListingViewCount`)
                .catch(error => {});
        }
    });
}

export function useUserProxyBid(vehicleListingId: number) {
    const { cancelToken, createNewCancelToken } = useCancelToken();
    createNewCancelToken();
    const { data: userProxyBid, execute: fetchUserProxyBid, } = useFetch(`/vehicles/existingProxyBid/${vehicleListingId}`, {
        cancelToken: cancelToken.value?.token,
        skipErrorOnCancel: true,
        onSuccess: (response, data) => {
            if (response == 'No proxy bid') {
                return data.value = undefined;
            }
        }
    });

    return {
        userProxyBid,
        fetchUserProxyBid,
    }
}

export function useEditableVDP({ vehicleListingId, originalListing, unsavedUpdates, announcements, bookoutOptions, tireTread, vehiclePhotos, vehiclePhotosDTO, sellerReason }: {
    vehicleListingId: number,
    unsavedUpdates: Ref<{[key: string]: any}>,
    originalListing: SRPListing,
    announcements?: Ref<VehicleListingAnnouncement[]>,
    bookoutOptions?: Ref<InspectionUpdateBookoutOptions>,
    tireTread?: Ref<InspectionDTO>,
    vehiclePhotos?: Ref<ImageDTO[]>,
    vehiclePhotosDTO?: Ref<VehiclePhotosDTO>,
    sellerReason?: Ref<string>
}) {
    const loadingSaveUpdates: Ref<boolean> = ref(false);
    const failedToSaveSections: Ref<string[]> = ref([]);

    // format inspection api > updateInspectionData payload - move pricing to unsavedUpdates.basicInfo
    function movePricingToBasicInfoForInspectionPayload() {
        let pricing = {};
        if (unsavedUpdates.value.pricing) {
            pricing = unsavedUpdates.value.pricing;
            unsavedUpdates.value.basicInfo = {
                ...unsavedUpdates.value.basicInfo,
                ...unsavedUpdates.value.pricing,
                canBuyItNowObject: {
                    ...unsavedUpdates.value.pricing,
                    buyItNowPrice: unsavedUpdates.value.pricing.buyItNowPrice ?? unsavedUpdates.value.pricing.reservePrice,
                },
            }
            delete unsavedUpdates.value.pricing;
        }
        return pricing;
    }

    function filterOutSellerReasonIfSellerTypeIsNotIndividual() {
        if (unsavedUpdates.value.sellerInfo?.sellerType !== 'Individual' && unsavedUpdates.value.sellerInfo?.sellerReason) {
            delete unsavedUpdates.value.sellerInfo.sellerReason;
        }
    }
  function filterOutBookoutOptionsIfNoName() {
    if (unsavedUpdates?.value?.bookoutOptions?.bookoutOptions) {
      // Filter out options where the name is an empty string
      unsavedUpdates.value.bookoutOptions.bookoutOptions = unsavedUpdates.value.bookoutOptions.bookoutOptions.filter(option => option.name !== "");
  }
    }

    async function saveAnnouncements(config: APIConfig={}) {
        return await updateInspectionData(vehicleListingId, unsavedUpdates.value.announcements, 'saveAnnouncements')
            .then(response => {
                applyAPIConfigOnSuccess(response, config);
                return response;
            }).catch(error => {
                applyAPIConfigOnError(error);
                const editVdpErrorMessage = formatEditVdpErrorMessage(error);
                openErrorDialog({
                    title: 'Failed to save announcements',
                    message: editVdpErrorMessage ?? `We encountered an error saving announcements. Please try again or contact support.`, 
                    error,
                });
            });
    }

    async function saveUpdates({ pricing, onSuccess, onError, onPromiseSuccess, onPromiseError }: {
            pricing?: { [key: string]: any }, 
            onSuccess?: (args: any) => void,
            onError?: (error?: Error) => void, 
            onPromiseSuccess?: (response: any) => void, 
            onPromiseError?: (error?: Error) => void,
        }={}
    ) {
        failedToSaveSections.value = [];
        loadingSaveUpdates.value = true;
        await Promise.all(Object.keys(unsavedUpdates.value).map(sectionName => {
            return updateInspectionData(vehicleListingId, unsavedUpdates.value[sectionName], `save${startCase(sectionName).replace(/ /g, '')}` as InspectionUpdateType)
                .then(response => {
                    if (onSuccess) {
                        onSuccess({
                            response, 
                            sectionName,
                            pricing,
                        });
                    }
                }).catch(error => {
                    if (formatEditVdpErrorMessage(error)) {
                        failedToSaveSections.value.push(formatEditVdpErrorMessage(error)!);
                    } else if (error?.response?.data?.error) {
                        failedToSaveSections.value.push(`<strong>${sectionName} - ${error.response.data.error}</strong>`)
                    } else {
                        failedToSaveSections.value.push(`${sectionName}, `);
                    }
                    if (onError) {
                        onError(error);
                    } else {
                        throw error;
                    }
                });
        })).then(res => {
            if (onPromiseSuccess) {
                onPromiseSuccess(res);
            }
            loadingSaveUpdates.value = false;
        }).catch(error => {
            if (onPromiseError) {
                onPromiseError(error);
            }
            loadingSaveUpdates.value = false;
            const celebrateValidationMessage = formatCelebrateValidationError(error);
            openErrorDialog({
                title: celebrateValidationMessage ? 'Failed to save: Invalid fields' : 'Some inspection fields failed to save',
                message: celebrateValidationMessage ?? `We encountered an error while saving the following fields: ${failedToSaveSections.value.join()}`,
                error,
            });
        });
    }

    const updatedListing = ref(originalListing);
    const listingKey: Ref<number> = ref(0);

    function updateListingBySection(sectionName: InspectionSectionName, updatedValue: any) {
        switch(sectionName) {
            case 'announcements':
                if (announcements) {
                    announcements.value = updatedValue;
                }
                break;
            case 'bookoutOptions':
                if (bookoutOptions) {
                    bookoutOptions.value = updatedValue;
                }
                break;
            case 'tireTreadDepth':
                if (tireTread) {
                    tireTread.value = updatedValue;
                }
                break;
            case 'basicInfo':
            case 'sellerInfo':
            case 'vehicleListingAttributes':
                updatedListing.value = { ...updatedListing.value, ...updatedValue };
                break;
            case 'vehiclePhotos':
                if (vehiclePhotos) {
                    vehiclePhotos.value = updatedValue;
                }
                break;
            case 'vehiclePhotosDTO':
                if (vehiclePhotosDTO) {
                    vehiclePhotosDTO.value = updatedValue;
                }
                break;
        }
    }

    function updateListingFromUnsavedUpdates(updates: { [sectionName: string]: any }) {
        Object.keys(updates).forEach(sectionName => {
            let updatedValue = updates[sectionName];
            if (updates[sectionName][sectionName]) { // announcements and bookout options are nested
                updatedValue = updates[sectionName][sectionName];
            }
            updateListingBySection(sectionName as InspectionSectionName, updatedValue);
        });
    }

    function updateListingFromSaveUpdatesResponse({ sectionName, response, pricing={} }: {
        sectionName: string,
        response: any,
        pricing?: any,
    }) {
        switch(sectionName) {
            case 'announcements':
                updateListingBySection(sectionName, unsavedUpdates.value.announcements.announcements);
                break;
            case 'bookoutOptions':
                updateListingBySection(sectionName, unsavedUpdates.value.bookoutOptions.bookoutOptions);
                break;
            case 'tireTreadDepth':
                updateListingBySection(sectionName, unsavedUpdates.value.tireTreadDepth);
                break;
            case 'basicInfo':
                updateListingBySection(sectionName, {
                    ...response,
                    auction: {
                        ...updatedListing.value.auction,
                        ...pricing,
                    }
                });
                break;
            case 'sellerInfo':
                updateListingBySection(sectionName, {
                    ...updatedListing.value,
                    ...unsavedUpdates.value.sellerInfo
                });
                if (response.sellerReason && sellerReason) {
                    sellerReason.value = response.sellerReason;
                }
                break;
            case 'vehiclePhotos':
                updateListingBySection(sectionName, response.vehiclePhotos);
                updateListingBySection('vehiclePhotosDTO', response.vehiclePhotosDTO);
                break;
        }
    }


    function updateListingVehiclePhotos({ vehiclePhotos, vehiclePhotosDTO }: {
        vehiclePhotos: ImageDTO[],
        vehiclePhotosDTO: VehiclePhotosDTO,
    }) {
        updateListingFromSaveUpdatesResponse({
            sectionName: 'vehiclePhotos', 
            response: {
                vehiclePhotos,
                vehiclePhotosDTO,
            }
        });
        listingKey.value++;
    }

    return {
        saveUpdates,
        loadingSaveUpdates,
        saveAnnouncements,
        failedToSaveSections,
        updatedListing,
        updateListingFromSaveUpdatesResponse,
        updateListingFromUnsavedUpdates,
        listingKey,
        movePricingToBasicInfoForInspectionPayload,
        filterOutSellerReasonIfSellerTypeIsNotIndividual,
        updateListingVehiclePhotos,
        filterOutBookoutOptionsIfNoName,
    }

}
