
import { computed, defineComponent, nextTick, PropType, Ref, ref } from 'vue';
import { useAnnouncementAutocomplete, useEditableFields } from '../composables';
import { VehicleListingAnnouncement } from '../types';
import { focusOnElement } from '../utils';
import { pick } from 'lodash';

import AppButton from './AppButton.vue';
import EditableDropdown from './Forms/EditableDropdown.vue';
import EditableField from './Forms/EditableField.vue';
import TheAnnouncement from './TheAnnouncement.vue';
import TheListingDetailsAnnouncements from './TheListingDetailsAnnouncements.vue';

type AnnouncementType = 'positive' | 'negative' | 'neutral';

export default defineComponent({
    name: 'TheListingDetailsAnnouncementsEditable',
    props: {
        announcements: {
            type: Array as PropType<VehicleListingAnnouncement[]>,
            default: () => { return [] },
        },
        isEditingMode: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        AppButton,
        EditableDropdown,
        EditableField,
        TheAnnouncement,
        TheListingDetailsAnnouncements,
    },
    emits: ['input', 'addAnnouncement'],
    setup(props, context) {
        const uneditableAnnouncements = props.announcements.filter(announcement => announcement.href);
        const updatedAnnouncements = ref([ ...props.announcements.map(announcement => pick(announcement, ['announcement', 'announcementType'])) ]);
        const announcementsKey = ref(0);
        const editingAnnouncementIdx: Ref<number | undefined> = ref(undefined);
       
        const { emitUpdates } = useEditableFields({ context });
        function updateAnnouncement(field: 'announcement' | 'announcementType', updatedValue: string | AnnouncementType, idx: number) {
            updatedAnnouncements.value[idx][field] = updatedValue as AnnouncementType; // TODO: fix this to also accommodate strings for announcement
        }

        function emitAnnouncements() {
            emitUpdates(updatedAnnouncements.value);
        }

        function addAnnouncement() {
            context.emit('addAnnouncement');
            if (!canAddAnnouncement.value) {
                return;
            }
            updatedAnnouncements.value.push({
                announcementType: 'neutral',
                announcement: '',
            });
            let newIdx = updatedAnnouncements.value.length - 1;
            editingAnnouncementIdx.value = newIdx;
            nextTick(() => {
                focusOnElement(`announcement-${newIdx}`);
            });
        }

        function deleteAnnouncement(idx) {
            updatedAnnouncements.value.splice(idx, 1);
            announcementsKey.value++;
        }

        // track which announcement idx we're editing to prevent updateAnnouncement from toggling editable field
        function updateEditingAnnouncementIdx(isEditing, idx) {
            editingAnnouncementIdx.value = isEditing ? idx : undefined;
        }

        const lastAnnouncement = computed(() => updatedAnnouncements.value.length ? updatedAnnouncements.value[updatedAnnouncements.value.length-1] : undefined);

        const canAddAnnouncement = computed(() => {
            if (!updatedAnnouncements.value.length) {
                return true;
            }
            return lastAnnouncement.value && lastAnnouncement.value.announcement;
        });

        const { debouncedGetAnnouncementAutocomplete, announcementPredictions, loadingAnnouncementPredictions } = useAnnouncementAutocomplete();

        return {
            editingAnnouncementIdx,
            updateEditingAnnouncementIdx,
            uneditableAnnouncements,
            updatedAnnouncements,
            updateAnnouncement,
            emitAnnouncements,
            addAnnouncement,
            canAddAnnouncement,
            deleteAnnouncement,
            announcementsKey,
            debouncedGetAnnouncementAutocomplete,
            announcementPredictions,
            loadingAnnouncementPredictions,
        }
    },
});
