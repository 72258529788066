<template>
    <AppButton
        class="nav-link no-border flex-row align-center animate-icon-shake"
        @click="$emit('click')"
        :icon-left="iconName"
    >
        <p v-if="linkText" class="is-poppins">{{ linkText }}</p>
    </AppButton>
</template>

<script>
import AppButton from './AppButton.vue';

export default {
    name: 'NavLinkWithAnimation',
    props: {
        linkText: {
            type: String,
            required: false,
        },
        iconName: {
            required: false,
            type: String,
            default: 'chevron-left',
        }
    },
    components: {
        AppButton,
    }
}
</script>

<style scoped lang='postcss'>
.nav-link {
    width: fit-content;
    padding: 0.5em 1em;
    color: var(--primary);

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}
</style>