
import { computed, defineComponent, onMounted, Ref, ref } from 'vue';
import { PropType } from 'vue/types/v3-component-props';
import { focusOnElement } from '../utils';

type AutocompleteInputOption = { [key: string]: any };

export default defineComponent({
    name: 'AutocompleteInput',
    props: {
        options: {
            type: Array as PropType<AutocompleteInputOption[]>,
            default: () => { return [] },
        },
        value: {
            type: Object as PropType<AutocompleteInputOption>,
            required: false,
        },
        field: { 
            // an AutocompleteInputOption key
            type: String,
            default: 'name',
        },
        autofocus: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['select', 'update:value', 'input'],
    setup(props, { emit }) {
        const input: Ref<string> = ref(props.value ? props.value[props.field] : '');
        
        onMounted(() => {
            if (props.autofocus) {
                focusOnElement('autocomplete-input');
            }
        });

        const filteredData = computed(() => {
            return props.options?.filter(option => {
                if (input.value && option[props.field]) {
                    return option[props.field].toString().toLowerCase().indexOf(input.value.toLowerCase()) >= 0;
                }
                return true;
            });
        });
        
        return {
            input,
            filteredData,
        }
    },
});
