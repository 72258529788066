import * as Sentry from '@sentry/vue';
import { storage } from '@/firebase';
import { AttachmentDTO } from '@/types/attachment';
import { ImageDTO } from '@/types/ImageDTO';
import { openToast } from './buefyUtils';

export function generateImageUrl(size, url, uniqueName) {
    const uniqueNameParts = uniqueName.split(".");
    const urlParts = url.split(uniqueName);
    if (urlParts.length !== 2) {
      return;
    }
    switch (size) {
      case "large":
        return (
          urlParts[0] +
          uniqueNameParts[0] +
          "_1000x1000." +
          uniqueNameParts[1] +
          urlParts[1]
        );
      case "medium":
        return (
          urlParts[0] +
          uniqueNameParts[0] +
          "_500x500." +
          uniqueNameParts[1] +
          urlParts[1]
        );
      case "small":
        return (
          urlParts[0] +
          uniqueNameParts[0] +
          "_250x250." +
          uniqueNameParts[1] +
          urlParts[1]
        );
      default:
        return url;
    }
}

export function chooseImgSize(type, size, vehiclePhotos) {
    try {
      if (size === "small" && vehiclePhotos[type].images[0].smallUrl) {
        return vehiclePhotos[type].images[0].smallUrl;
      } else if (size === "medium" && vehiclePhotos[type].images[0].mediumUrl) {
        return vehiclePhotos[type].images[0].mediumUrl;
      } else if (size === "large" && vehiclePhotos[type].images[0].largeUrl) {
        return vehiclePhotos[type].images[0].largeUrl;
      } else if (size === "original" && vehiclePhotos[type].images[0].url) {
        return vehiclePhotos[type].images[0].url;
      } else {
        return vehiclePhotos[type].images[0].url;
      }
    } catch (error) {
      return "/img/defaultimg.796b1e31.jpg";
    }
}

export async function uploadAttachments(val) {
    const files = val;
    this.fileLoading = true;
  files.forEach((file) => {
    const fileName = file.name.replace(/ /g, '');
      let words = fileName.split(".");
      let extension = ""
      let name = ""
      if(words.length>2){
        extension = words[words.length - 1]
        words.pop()
        name = words.join('')
      }
      else{
        [name, extension] = words
      }
      const uniqueName = name + Date.now().toString() + "." + extension;
      let fileType = extension;
      if (fileType === "pdf") {
        const storageRef = storage.ref(`${this.claimId}/attachments/pdf/${uniqueName}`);
        const task = storageRef.put(file);
        let percentage;
        task.on(
          "state_changed",
          (snapshot) => {
            percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => console.log({ error }),
          () => {
            storageRef.getDownloadURL().then((url) => {
              this.returnedAttachments.push({
                filename: uniqueName,
                fileType,
                url,
              });
              this.imageLoading = false;
              this.returnAttached(this.returnedAttachments); // TODO: remove this and only use the emit below
              this.$emit('attached', this.returnedAttachments);
              this.$emit('update:loading', false);
            });
          }
        );
      } else if (
        fileType === "jpeg" ||
        fileType === "png" ||
        fileType === "pdf" ||
        fileType === "jpg"
      ) {
        const storageRef = storage.ref(`${this.claimId}/attachments/images/${uniqueName}`);
        const task = storageRef.put(file);
        let percentage;
        task.on(
          "state_changed",
          (snapshot) => {
            percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => console.log({ error }),
          () => {
            storageRef.getDownloadURL().then((url) => {
              const smallUrl = this.generateImageUrl("small", url, uniqueName);
              const mediumUrl = this.generateImageUrl("medium", url, uniqueName);
              const largeUrl = this.generateImageUrl("large", url, uniqueName);
  
              this.returnedAttachments.push({
                filename: uniqueName,
                fileType,
                progress: percentage,
                url,
                smallUrl,
                mediumUrl,
                largeUrl,
              });
              this.imageLoading = false;
              this.returnAttached(this.returnedAttachments); // TODO: remove this and only use the emit below
              this.$emit('attached', this.returnedAttachments);
              this.$emit('update:loading', false);
            });
          }
        );
      } else {
        throw new Error(`${fileType} is not a supported file type`);
      }
    });
}

export function deleteAttachment(fileName, index) {
const attachmentUrl = fileName;

const attachmentRefURL = storage.refFromURL(attachmentUrl);
const [name, extension] = attachmentRefURL.name.split(".");
let fileType = extension;

let path = "images";
if (fileType === "pdf") path = "pdf";

const attachmentRef = storage
    .ref()
    .child(`${this.claimId}/attachments/${path}/${attachmentRefURL.name}`);

attachmentRef
    .delete()
    .then((response) => {
    this.$buefy.toast.open({
        message: "Attachment deleted",
        type: "is-success",
    });
    // Remove from array
    this.returnedAttachments.splice(index, 1);
    })
    .catch((error) => {
    this.$buefy.toast.open({
        message:
        "There was a problem removing your attachment, please try again later.",
        type: "is-danger",
    });
    });
}

export async function uploadAttachmentsToStorage(filePath: string, files: AttachmentDTO[]) {
  const uploadedFiles: ImageDTO[] = [];
  const promises = files.map((file) => this.formatAndUploadFile(filePath, file, uploadedFiles, file.name)
    .catch((error) => {
      openToast('is-danger', error);
      Sentry.captureException(error);
      throw error;
    }));

  return await Promise.all(promises);
}


export async function formatAndUploadFile(filePath: string, file: { name: string },  uploadedFiles: Array<ImageDTO>, fileName?: string,): Promise<ImageDTO[]> {
  let words = file.name.split('.');
  let extension = '';
  let name = '';

  if (words.length > 2) {
    extension = words[words.length-1];
    words.pop();
    name = words.join('');
  } else {
    [name, extension] = words;
  }

  if (!fileName) {
   fileName = name + Date.now().toString() + '.' + extension;
  }

  let fileType = extension;

  const allowedTypes = ['pdf', 'jpeg', 'png', 'jpg', 'webp'];

  if (!allowedTypes.includes(fileType)) {
    throw new Error(`${fileType} is not a supported file type`);
  }

  return await this.uploadFile(filePath, fileName, file, fileType);
}

export function uploadFile(filePath: string, filename: string, file: any, fileType: string) {
  return new Promise((resolve, reject) => {
    const storageRef = storage.ref(`${filePath}/${filename}`);
    const task = storageRef.put(file);
    let percentComplete: number;
    task.on(
      'state_changed',
      (snapshot) => {
        percentComplete = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      },
      (error) => {
        reject(error);
      },
      () => {
        storageRef.getDownloadURL().then((url) => {
          const smallUrl = generateImageUrl('small', url, filename);
          const mediumUrl = generateImageUrl('medium', url, filename);
          const largeUrl = generateImageUrl('large', url, filename);

          const uploadedFile: ImageDTO & { fileType: string, added: boolean } = {
            progress: percentComplete,
            filename,
            url,
            fileType,
            smallUrl,
            mediumUrl,
            largeUrl,
            added: true,
          };
          resolve(uploadedFile);
          return uploadedFile;
        });
      },
    );
  });
}
