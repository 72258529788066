import { assignInspectorToListing, getAnnouncementPrediction, getProfileLiteByRole, selfAssignInspection } from '@/api';
import { APIConfig, ProfileLiteDTO } from '@/types';
import { applyAPIConfigOnSuccess, isUserInspector, openCancelInspectionRequestConfirmationDialog, openRequestInspectionConfirmationDialog, openToast } from '@/utils';
import { debounce } from 'lodash';
import { Ref, ref, SetupContext } from 'vue';
import { useUser } from './user';

export interface WholesaleRecommendationMaterialized {
  inventoryId: number,
  storeId: number | null,
  year: string | null,
  make: string | null,
  model: string | null,
  trim: string | null,
  vin: string | null,
  stockNumber: string | null,
  miles: number | null,
  daysOnLot: number | null,
  mds: number | null,
  internetPrice: number | null,
  cost: number | null,
  expectedHoldingCost: number | null,
  status: string | null,
  vehicleListingId: number,
  inspectionRequestDate?: Date | null,
}

export function useRequestInspection(tableData: Ref<WholesaleRecommendationMaterialized[]>) {
    const loadingActionButtonInventoryIds = ref<number[]>([]);

    function toggleLoadingActionButton(loading: boolean, inventoryId: number) {
        const loadingIdx = loadingActionButtonInventoryIds.value.findIndex((id) => id == inventoryId);
        if (loading && loadingIdx < 0) { // loading and not already in array
            loadingActionButtonInventoryIds.value.push(inventoryId);
        } else if (!loading && loadingIdx >= 0) { // not loading and in array
            loadingActionButtonInventoryIds.value.splice(loadingIdx, 1);
        }
    }

    function requestInspection(inventoryId: number) {
        toggleLoadingActionButton(true, inventoryId);
        openRequestInspectionConfirmationDialog(
            inventoryId,
            {
                onCancel: () => toggleLoadingActionButton(false, inventoryId),
            },
            {
                onSuccess: (res) => {
                    const tableRow = tableData.value.find((row) => row.inventoryId == inventoryId);
                    if (tableRow) {
                        tableRow.inspectionRequestDate = res;
                    }
                    toggleLoadingActionButton(false, inventoryId);
                },
                onError: () => toggleLoadingActionButton(false, inventoryId),
            },
        );
    }

    function cancelInspection(inventoryId: number) {
        toggleLoadingActionButton(true, inventoryId);
        openCancelInspectionRequestConfirmationDialog(
            inventoryId,
            {
                onCancel: () => toggleLoadingActionButton(false, inventoryId),
            },
            {
                onSuccess: () => {
                    const tableRow = tableData.value.find((row) => row.inventoryId == inventoryId);
                    if (tableRow) {
                        tableRow.inspectionRequestDate = null;
                    }
                    toggleLoadingActionButton(false, inventoryId);
                },
                onError: () => toggleLoadingActionButton(false, inventoryId),
            },
        );
    }

    function requestOrCancelInspection(row: WholesaleRecommendationMaterialized) {
        if (row.inspectionRequestDate) {
            cancelInspection(row.inventoryId);
        } else {
            requestInspection(row.inventoryId);
        }
    }

    return {
        loadingActionButtonInventoryIds,
        requestOrCancelInspection,
    };
}


export function useAssignInspector({ vehicleListingId, inspectorPersonId, inspectorOptions, context }: {
    vehicleListingId: number,
    inspectorPersonId?: number,
    inspectorOptions: Ref<ProfileLiteDTO[]>,
    context: SetupContext<('select' | any)[]>
}) {
    const assignedInspector: Ref<ProfileLiteDTO | undefined> = ref(undefined);
    const assignedInspectorPersonId: Ref<number | undefined> = ref(undefined);
    const inspectorKey: Ref<number> = ref(0);
    const loadingSaveInspector: Ref<boolean> = ref(false);

    function assignInspectorFromInspectorOptions(personId: number) {
        let inspector = inspectorOptions.value.find(profileLite => profileLite.personId == personId);
        if (inspector) {
            updateAssignedInspector(inspector);
        }
    }

    const user = useUser();
    async function assignLoggedInInspector() {
        let loggedInPersonId = user.value?.profile?.id ? Number(user.value.profile.id) : undefined;
        if (isUserInspector() && loggedInPersonId) {
            loadingSaveInspector.value = true;
            selfAssignInspection(vehicleListingId, {
                onSuccess: (res) => {
                    loadingSaveInspector.value = false;
                    assignInspectorFromInspectorOptions(loggedInPersonId!);
                    openToast('is-success', res);
                },
                onError: () => loadingSaveInspector.value = false,
            });
        }
    }

    async function updateAssignedInspector(inspector?: ProfileLiteDTO) {
        assignedInspector.value = inspector;
        assignedInspectorPersonId.value = inspector?.personId;
        context.emit('select', inspector);
        inspectorKey.value++;
    }

    async function assignInspector() {
        loadingSaveInspector.value = true;
        await assignInspectorToListing(assignedInspectorPersonId.value ?? null, vehicleListingId, {
            onSuccess: () => {
                loadingSaveInspector.value = false;
                assignedInspectorPersonId.value 
                    ? openToast('is-success', 'Inspector assigned!')
                    : openToast('is-success', 'Inspector unassigned');
            },
            onError: () => loadingSaveInspector.value = false,
        });
    }

    return {
        assignedInspector,
        assignedInspectorPersonId,
        assignInspectorFromInspectorOptions,
        assignLoggedInInspector,
        assignInspector,
        updateAssignedInspector,
        inspectorKey,
        loadingSaveInspector,
    }
}

export function useGetInspectorOptions() {
    const inspectorOptions: Ref<ProfileLiteDTO[]> = ref([]);
    const loadingInspectorOptions: Ref<boolean> = ref(false);
    
    async function getInspectorOptions(config: APIConfig={}) {
        loadingInspectorOptions.value = true;
        await getProfileLiteByRole(['inspector', 'admin'], undefined, {
            onSuccess: (res) => {
                loadingInspectorOptions.value = false;
                inspectorOptions.value = res;
                applyAPIConfigOnSuccess(res, config);
            },
            onError: () => loadingInspectorOptions.value = false,
        });
    }

    return {
        inspectorOptions,
        loadingInspectorOptions,
        getInspectorOptions,
    }
}

export function useAnnouncementAutocomplete() {
    const announcementPredictions: Ref<string[]> = ref([]);
    const loadingAnnouncementPredictions: Ref<number | undefined> = ref(undefined);

    const debouncedGetAnnouncementAutocomplete = debounce(getAnnouncementAutocomplete, 300);

    async function getAnnouncementAutocomplete(announcement: string, idx?: number) {
        if (!announcement) {
            return;
        }
        loadingAnnouncementPredictions.value = idx;
        announcementPredictions.value = await getAnnouncementPrediction(announcement, {
            onSuccess: () => loadingAnnouncementPredictions.value = undefined,
            onError: () => loadingAnnouncementPredictions.value = undefined,
        });
    }

    return {
        debouncedGetAnnouncementAutocomplete,
        announcementPredictions,
        loadingAnnouncementPredictions,
    }
}