var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.loadingVehicleListing)?_c('AppListingDetailsLoading',_vm._b({},'AppListingDetailsLoading',_vm.$attrs,false)):(_vm.route?.name == 'Review Your Listing' && _vm.isUserBuyer)?_c('AppListingDetailsSeller',_vm._b({},'AppListingDetailsSeller',{
        listing: _vm.vehicleListing,
        marketplaceListingId: _vm.marketplaceListingId,
        ..._vm.$attrs,
    },false)):(_vm.route?.name == 'Inspection Details' && (_vm.isUserAdmin || _vm.isUserDsr || _vm.isUserInspector))?_c('AppListingDetailsInspector',_vm._b({},'AppListingDetailsInspector',{ 
        listing: _vm.vehicleListing,
        marketplaceListingId: _vm.marketplaceListingId,
        ..._vm.$attrs 
    },false)):(_vm.marketplaceListingId)?_c('AppListingDetailsMarketplace',_vm._b({},'AppListingDetailsMarketplace',{
        listing: _vm.vehicleListing,
        marketplaceListingId: _vm.marketplaceListingId,
        ..._vm.$attrs,
    },false)):(_vm.isUserBuyer)?_c('AppListingDetailsBuyer',_vm._b({on:{"watching":function($event){return _vm.$emit('watching', $event)}}},'AppListingDetailsBuyer',{ 
        listing: _vm.vehicleListing,
        displayOverdrive: true,
        ..._vm.$attrs 
    },false)):(_vm.isUserAdmin || _vm.isUserDsr)?_c('AppListingDetailsAdmin',_vm._b({},'AppListingDetailsAdmin',{
        listing: _vm.vehicleListing,
        displayOverdrive: true,
        ..._vm.$attrs,
    },false)):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }