var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AppListingDetails',_vm._b({key:`listing-details-${_vm.vehicleKey}-${_vm.isEditingMode}`,class:{ 'editing-mode': _vm.isEditingMode },scopedSlots:_vm._u([{key:"buttons",fn:function(){return [(_vm.$attrs.fixedButtons)?_c('div',{staticClass:"fixed-buttons-container"},[_c('div',{staticClass:"fixed-buttons flex-row"},[_vm._t("buttons")],2)]):_vm._t("buttons")]},proxy:true},{key:"carouselButtons",fn:function(){return [_vm._t("carouselButtons"),(_vm.isEditingMode && _vm.vehiclePhotosDTO && _vm.isSectionEditable('photos'))?_c('TheEditListingPhotosButton',_vm._b({key:`photos-${_vm.$attrs.loadingVehiclePhotos}`,on:{"saved":_vm.updatePhotosAfterSaving}},'TheEditListingPhotosButton',{
                vehicleListingId: _vm.vehicleListingId,
                vehiclePhotos: _vm.vehiclePhotosDTO,
                expanded: true,
                type: 'is-primary-dark',
            },false)):_vm._e()]},proxy:true},{key:"listingStats",fn:function(){return [(_vm.isSectionEditable('pricing'))?_c('TheListingDetailsPricingEditable',_vm._b({staticClass:"has-text-white",class:{
                'marketplace-listing': Boolean(_vm.marketplaceListingId),
                'is-editing': _vm.isEditingMode,
                'buttons-row': _vm.windowWidth > 1100 || (_vm.windowWidth >= 650 && _vm.windowWidth < 768),
                'buttons-stack': _vm.windowWidth <= 1100 || (_vm.windowWidth < 650),
                'inputs-row': _vm.windowWidth > 500,
                'inputs-stack': _vm.windowWidth <= 500,
            },on:{"input":function($event){return _vm.emitUpdates({ pricing: $event })}}},'TheListingDetailsPricingEditable',{
                vehicleListingId: _vm.vehicleListingId,
                startingPrice: _vm.listing.auction?.startingPrice ?? _vm.listing.auction?.askingPrice,
                reservePrice: _vm.listing.auction?.reservePrice,
                buyItNowPrice: _vm.listing.auction?.buyItNowPrice,
                canBuyItNow: _vm.listing.auction?.canBuyItNow,
                isEditingMode: _vm.isEditingMode,
                isMarketplaceListing: Boolean(_vm.marketplaceListingId),
                additionalPricingDetails: _vm.additionalPricingDetails,
                loadingAdditionalPricingDetails: _vm.$attrs.loadingAdditionalPricingDetails,
            },false)):_vm._e()]},proxy:true},{key:"vehicleBasics",fn:function(){return [(_vm.isSectionEditable('vehicleBasics'))?_c('TheListingDetailsVehicleBasicsEditable',_vm._b({on:{"input":function($event){return _vm.emitUpdates({ basicInfo: $event })}}},'TheListingDetailsVehicleBasicsEditable',{
                year: _vm.listing.year,
                make: _vm.listing.brand,
                model: _vm.listing.model,
                trim: _vm.listing.trim,
                vin: _vm.listing.vin,
                miles: _vm.listing.mileage,
                zip: _vm.listing.zip,
                city: _vm.listing.city,
                state: _vm.listing.state,
                isEditingMode: _vm.isEditingMode,
            },false)):_vm._e()]},proxy:true},{key:"vehicleAttributes",fn:function(){return [(_vm.isSectionEditable('vehicleAttributes') && _vm.isEditingMode)?_c('TheListingDetailsVehicleAttributesEditable',_vm._b({staticClass:"flex-row align-center justify-center mt-3",on:{"input":function($event){return _vm.emitUpdates({ 'vehicleListingAttributes': $event })}}},'TheListingDetailsVehicleAttributesEditable',{
                isInoperable: _vm.listing.isInoperable,
                isWholesale: _vm.listing.isWholesale,
                isFrontline: _vm.listing.isFrontline,
            },false)):_vm._e()]},proxy:true},{key:"sellerDetails",fn:function(){return [(_vm.isSectionEditable('sellerDetails'))?_c('TheListingDetailsSellerDetailsEditable',_vm._b({key:`seller-details-${_vm.$attrs.loadingSellerReason}`,on:{"input":function($event){return _vm.emitUpdates({ sellerInfo: $event })}}},'TheListingDetailsSellerDetailsEditable',{
                titleStatus: _vm.listing.titleStatus,
                sellerType: _vm.listing.sellerType,
                sellerReason: _vm.sellerReason,
                store: _vm.listing.sellerStore?.name,
                facilitatingAuctionStoreName: _vm.listing.facilitatingAuction?.name,
                sellerStoreId: _vm.listing.sellerStore?.id,
                isEditingMode: _vm.isEditingMode,
            },false)):_vm._e()]},proxy:true},{key:"sellerDetailsMobile",fn:function(){return [(_vm.isSectionEditable('sellerDetails'))?_c('TheListingDetailsSellerDetailsEditable',_vm._b({key:`seller-details-${_vm.$attrs.loadingSellerReason}`,staticClass:"seller-details-mobile",on:{"input":function($event){return _vm.emitUpdates({ sellerInfo: $event })}}},'TheListingDetailsSellerDetailsEditable',{
                titleStatus: _vm.listing.titleStatus,
                sellerType: _vm.listing.sellerType,
                sellerReason: _vm.sellerReason,
                store: _vm.listing.sellerStore?.name,
                facilitatingAuctionStoreName: _vm.listing.facilitatingAuction?.name,
                sellerStoreId: _vm.listing.sellerStore?.id,
                isEditingMode: _vm.isEditingMode,
            },false)):_vm._e()]},proxy:true},{key:"announcements",fn:function(){return [(_vm.isSectionEditable('announcements'))?_c('TheListingDetailsAnnouncementsEditable',_vm._b({key:`announcements-${_vm.$attrs.loadingAnnouncements}`,on:{"input":function($event){return _vm.emitUpdates({ announcements: { announcements: $event } })},"addAnnouncement":function($event){return _vm.$emit('addAnnouncement')}}},'TheListingDetailsAnnouncementsEditable',{
                announcements: _vm.announcements,
                isEditingMode: _vm.isEditingMode,
                loading: _vm.$attrs.loadingAnnouncements,
            },false)):_vm._e()]},proxy:true},{key:"marketData",fn:function(){return [_c('div')]},proxy:true},{key:"bookoutOptions",fn:function(){return [_c('TheListingDetailsBookoutOptionsEditable',_vm._b({key:`bookout-options-${_vm.$attrs.loadingBookoutOptions}`,on:{"input":function($event){return _vm.emitUpdates({ bookoutOptions: { bookoutOptions: $event} })}}},'TheListingDetailsBookoutOptionsEditable',{
                loading: _vm.$attrs.loadingBookoutOptions,
                bookoutOptions: _vm.bookoutOptions,
                isEditingMode: _vm.isEditingMode,
            },false),[(!_vm.isSectionEditable('bookoutOptions'))?_c('div',{staticClass:"has-text-grey-light"},[_vm._v(" Details will be reviewed and updated by our team ")]):_vm._e()])]},proxy:true},{key:"bidHistory",fn:function(){return [_vm._t("bidHistory",function(){return [_c('div')]})]},proxy:true},{key:"tireTreadDepth",fn:function(){return [_vm._t("tireTreadDepth",function(){return [_c('TheListingDetailsTireTreadEditable',_vm._b({key:`tire-tread-${_vm.$attrs.loadingTireTread}`,on:{"input":function($event){return _vm.emitUpdates({ tireTreadDepth: $event })}}},'TheListingDetailsTireTreadEditable',{
                    inspectionDTO: _vm.tireTread,
                    isEditingMode: _vm.isEditingMode,
                },false),[(!_vm.isSectionEditable('tireTread'))?_c('div',{staticClass:"has-text-grey-light"},[_vm._v(" Details will be reviewed and updated by our team ")]):_vm._e()])]})]},proxy:true}],null,true)},'AppListingDetails',{
        ..._vm.$props,
        carouselAttrs: {
            allowKeyboardNavigation: !_vm.isEditingMode,
        }
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }