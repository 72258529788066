var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-field',_vm._b({},'b-field',_vm.$attrs.fieldAttrs,false),[_c('b-dropdown',_vm._b({on:{"input":function($event){_vm.$emit('select', $event); _vm.$emit('update:value', $event)}},scopedSlots:_vm._u([{key:"trigger",fn:function({ active }){return [_vm._t("trigger",function(){return [_c('AppButton',_vm._b({staticClass:"dropdown-button carmigo-button"},'AppButton',{
                        iconRight: active ? 'menu-up' : 'menu-down',
                        ..._vm.$attrs.buttonAttrs,
                    },false),[_vm._t("buttonLabel",function(){return [_vm._v(" "+_vm._s(_vm.placeholder)+" ")]})],2)]},null,{ active })]}}],null,true),model:{value:(_vm.selectedOption),callback:function ($$v) {_vm.selectedOption=$$v},expression:"selectedOption"}},'b-dropdown',{
            position: 'is-bottom-left',
            ..._vm.$attrs,
        },false),[(!_vm.options.length)?_vm._t("empty"):_vm._e(),_vm._l((_vm.options),function(option,idx){return _c('b-dropdown-item',{key:`dropdown-item-${idx}`,attrs:{"value":_vm.optionValueKey ? option[_vm.optionValueKey] : option,"aria-role":"listitem"}},[_vm._t("dropdownItem",function(){return [_vm._v(" "+_vm._s(_vm.optionLabelKey ? option[_vm.optionLabelKey] : option)+" ")]},null,{ option })],2)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }