import { GET, getFilterPresetsForUser, POST, saveUserFilterPreset, updateUserFilterPreset } from "@/api";
import { Filter, FilterFieldConfig, FilterFieldName, FilterFieldOption, FilterFieldOptionBoolean, FilterFieldOptionRange, FilterInputType, NotificationSetting, SavedFilter, SelectedFilter, SelectedFilters, UserSavedFilterDTO, VehicleFilter, VehicleFilterPreset, VehicleStatus } from '@/types';
import { applyFilterMask, checkForSavedFilterChanges, clearFilterField, convertSelectedFiltersToUserSavedFilterDTO, convertUserSavedFilterToSavedFilter, convertVehicleFilterToGetFilterSearchTermsDTO, convertVehicleFilterToVehicleFilterPreset, dollarAmountToInt, filterOutResultsWithCountZero, getActiveFilters, getFilterFieldConfigs, getFilterFieldOptions, getFilterFieldsWithFilterValues, getMinMaxAsFilterFieldOptionRangeArray, getNonEmptySelectedOptionValues, mapStringArrayToFilterFieldOptionArray, normalizeFilterNotifications, omitFilterFields, openErrorDialog, openModal, removeInputMask, updateFilterUrlParams, } from "@/utils";
import { BModalComponent } from "buefy/types/components";
import { isEqual, pick } from "lodash";
import { computed, ComputedRef, onBeforeMount, onMounted, reactive, Ref, ref, SetupContext, watch } from "vue";
import { useBreakpoint } from "./breakpoint";
import { useStore } from "./useStore";
import { useRoute } from "vue-router/composables";
import { Route } from "@sentry/vue/types-ts3.8/router";

import TheFilterEditPresetModal from '../components/Filters/TheFilterEditPresetModal.vue';

export function useGetFiltersForUser(filtersKey?: Ref<number>) {
    const savedPresets: Ref<VehicleFilterPreset[]> = ref([]);
    const loadingPresets: Ref<Boolean> = ref(false);

    async function getSavedPresets() {
        loadingPresets.value = true;
        savedPresets.value = await getFilterPresetsForUser();
        loadingPresets.value = false;
        if (filtersKey) {
            filtersKey.value++;
        }
    }

    return {
        savedPresets,
        loadingPresets,
        getSavedPresets,
    }
}

export function useCheckForFilterUpdates() {
    const filterHasChanges = ref(false);
    const store = useStore();

    function checkForFilterUpdates(selectedFilterPreset: VehicleFilterPreset, updatedFilters: VehicleFilter | null) {
        if (!updatedFilters) {
            filterHasChanges.value = false;
            return;
        }

        if (!selectedFilterPreset) {
            let active = getActiveFilters(updatedFilters);
            filterHasChanges.value = Boolean(active.length);
            return;
        }

        filterHasChanges.value = Object.keys(updatedFilters).some(field => {
            const updatedValue = updatedFilters[field as keyof VehicleFilter];
            const presetValue = selectedFilterPreset[field as keyof VehicleFilterPreset];
            if (updatedValue == null || presetValue == null) {
                return Boolean(updatedValue) !== Boolean(presetValue);
            } else {
                return JSON.stringify(updatedValue) !== JSON.stringify(presetValue);
            }
        });
    }

    return {
        checkForFilterUpdates,
        filterHasChanges,
    }

}

type FilterFieldOptionOld = {
    displayName: string,
    value: string,   
}
type FilterFieldPossibleValues = {
    sellerType: FilterFieldOptionOld[],
    make: FilterFieldOptionOld[],
    model: FilterFieldOptionOld[],
    trim: FilterFieldOptionOld[],
    bodyType: FilterFieldOptionOld[],
    fuelType: FilterFieldOptionOld[],
}
export function useGetPossibleValuesForFilterFields(selectedFiltersObject: VehicleFilter, initialSearchCriteria: { [key: string]: any }) {
    const possibleValues: FilterFieldPossibleValues = reactive({
        sellerType: [],
        sellerStore: [],
        make: [],
        model: [],
        bodyType: [],
        trim: [],
        fuelType: [],
    });

    const loadingPossibleValuesForField = ref(undefined);
    async function getPossibleValuesForFilterFields(field=undefined) {
        loadingPossibleValuesForField.value = field;
        const filtersForFilterSearchTerms = convertVehicleFilterToGetFilterSearchTermsDTO(selectedFiltersObject, field);
        const response = await POST(`/filters/filterSearchTerms`, { 
            filters: {
                ...filtersForFilterSearchTerms,
                ...initialSearchCriteria,
            } 
        }).then(res => res.data);
        Object.keys(response).forEach(fieldName => {
            const fieldsToNotUpdate = ['year', 'highestBid', 'distance', 'carmigoDirectStatus'];
            if (!fieldsToNotUpdate.includes(fieldName)) {
                updatePossibleFilterValues(fieldName as keyof FilterFieldPossibleValues, response[fieldName]);
            }
        });
        loadingPossibleValuesForField.value = undefined;
    }

    function updatePossibleFilterValues(fieldName: keyof FilterFieldPossibleValues, newPossibleValues: Array<{ name: string, count: number, id?: number }>) {
        const currPossibleValues = possibleValues[fieldName];
        if (!newPossibleValues || isEqual(currPossibleValues, newPossibleValues)) {
            return;
        }
        possibleValues[fieldName] = newPossibleValues.map((value) => {
            const count = value.count ? ` (${value.count})` : '';
            const valueWithCount = `${value.name}${count}`;
            return {
                displayName: value.name,
                value: valueWithCount
            }
        });
    }

    return {
        loadingPossibleValuesForField,
        possibleValues,
        getPossibleValuesForFilterFields,
    }
}

export function useFiltersModal() {
    const { windowWidth } = useBreakpoint();

    let filtersModal: BModalComponent | null = null;
    const filtersModalKey = ref(0);
    watch(windowWidth, () => {
        if (windowWidth.value > 725 && filtersModal) {
            filtersModalKey.value++;
            filtersModal.close();
            filtersModal = null;
        }
    });

    function getModal(modal: BModalComponent) {
        filtersModal = modal;
    }

    return {
        filtersModal,
        filtersModalKey,
        getModal,
    }
}

type PresetDetails = {
    presetName: string,
    notifications: {
        email: boolean,
        text: boolean
    }
}

export function useSaveUpdatedFilter(selectSavedFilter: Function, getSavedPresets: Function) {
    const loadingSaveFilter = ref(false);

    function openUpdateOrCreateSavedFilterModal(updatedFilter: VehicleFilter, selectedFilterName: string, filterPresetId?: number) {
        openModal({
            component: TheFilterEditPresetModal,
            props: {
                canUpdateExisting: filterPresetId !== null && filterPresetId !== undefined,
            },
            events: {
                updatePreset: () => updateSavedFilter(updatedFilter, selectedFilterName, filterPresetId),
                addPreset: (presetDetails: PresetDetails) => createSavedFilter(updatedFilter, presetDetails),
            }
        });
    }

    async function updateSavedFilter(updatedFilter: VehicleFilter, selectedFilterName: string, filterPresetId?: number) {
        if (!filterPresetId) {
            openErrorDialog({
                title: 'Could not save updated filter',
                message: `No saved filter ID was provided to update the filter. Filter name: ${selectedFilterName}.`
            });
            return;
        }
        loadingSaveFilter.value = true;
        const newPreset = convertVehicleFilterToVehicleFilterPreset(updatedFilter, selectedFilterName);
        await updateUserFilterPreset(filterPresetId, newPreset);
        await getSavedPresets();
        selectSavedFilter(newPreset);
        loadingSaveFilter.value = false;
    }

    async function createSavedFilter(updatedFilter: VehicleFilter, { presetName, notifications }: PresetDetails) {
        loadingSaveFilter.value = true;
        const newPreset = convertVehicleFilterToVehicleFilterPreset(updatedFilter, presetName);
        const presetNotifications = normalizeFilterNotifications(notifications);
        const newPresetId = await saveUserFilterPreset(newPreset, presetNotifications);
        await getSavedPresets();
        selectSavedFilter({ ...newPreset, id: newPresetId });
        loadingSaveFilter.value = false;
    }

    return {
        openUpdateOrCreateSavedFilterModal,
        loadingSaveFilter,
    }
}

export function useListingFiltersSelected({ context, stateFilters, clearStateFilters, updateStateFilters }: {
    context: SetupContext,
    stateFilters: VehicleFilter,
    clearStateFilters: () => void,
    updateStateFilters: (payload: VehicleFilter) => void,
}) {
    const selectedFilters = computed(() => getFilterFieldsWithFilterValues(stateFilters));

    function clearAllFilters() {
        clearStateFilters();
        context.emit('clearAll');
    }

    function clearFilter(filterName: keyof VehicleFilter) {
        const filtersWithFieldCleared = clearFilterField(stateFilters, filterName);
        updateStateFilters(filtersWithFieldCleared);
        context.emit('clearFilter', filterName);
    }

    return {
        clearFilter,
        clearAllFilters,
        selectedFilters,
    }
}

export async function useGetFiltersFromUrlParams({ context, savedFilterKey, selectedSavedFilterId, selectedFilters, filterFieldNames, filters }: {
    selectedSavedFilterId: Ref<number | undefined>,
    selectedFilters: Ref<SelectedFilters | undefined>,
    filterFieldNames: FilterFieldName[],
    filters: Filter,
    savedFilterKey: Ref<number>,
    context: SetupContext<('updateFilters')[]>,
}) {
    let route = useRoute();
    let urlParams = route.query;

    // SAVED FILTERS
    let urlSavedFilterId: number | undefined = undefined;
    if (urlParams.savedFilter) {
        urlSavedFilterId = parseInt(urlParams.savedFilter as string);
        selectedSavedFilterId.value = urlSavedFilterId;
    }

    // FILTERS
    const urlFilters: Ref<SelectedFilters> = ref({});
    await Promise.all(filterFieldNames.map(async filterFieldName => {
        if (filters[filterFieldName] == undefined) {
            return;
        }
        // get the config from Filters
        let filterFieldConfig = filters[filterFieldName]!.config;
        if (!filterFieldConfig) {
            return;
        }

        // check for URL param value for this filterFieldName
        let urlParamValue = urlParams[filterFieldName];
        if (!urlParamValue || !urlParamValue.length) {
            return;
        }
        let options: FilterFieldOption[] = [];

        // if the url param value is an ID, have to fetch filter field options in order to get the displayName
        if (filterFieldConfig.isValueId) {
            const store = useStore();
            // get filter field options
            let filterFieldOptions = await getFilterFieldOptions({
                fieldName: filterFieldName,
                selectedFilters: omitFilterFields({
                    filters: selectedFilters as Filter,
                    fieldsToOmit: [filterFieldName]
                }),
                vehicleStatuses: ['Auctioning'] as VehicleStatus[],
                loggedInPersonId: store.state.user.profile.id,
                config: {
                    dontFetchForFields: ['year', 'distance', 'price', 'mileage'],
                    transformResults: ['sellerType'].includes(filterFieldName) ? filterOutResultsWithCountZero : undefined,
                }
            });
            if (!filterFieldOptions) {
                return;
            }
            // match the url param ID with the filterFieldOption to get its displayName 
            let displayNames = typeof urlParamValue == 'object' ? urlParamValue : [urlParamValue] as any[];
            displayNames = displayNames.map((urlParamVal: any) => {
                return (filterFieldOptions as FilterFieldOption[]).find(option => option.value == urlParamVal);
            });
            options = displayNames.filter(displayName => displayName !== undefined);
        } else {
            const filterDisplayName = filters[filterFieldName as FilterFieldName]!.displayName;
            // format the filter option depending on config.filterType
            switch(filterFieldConfig.filterType) {
                case 'range':
                    let range: FilterFieldOptionRange[] | undefined = getMinMaxAsFilterFieldOptionRangeArray(parseInt(urlParamValue[0] as string), parseInt(urlParamValue[1] as string));
                    if (range) {
                        options = range;
                    }
                    break;
                case 'tag':
                    let tags: FilterFieldOption[] | undefined =  mapStringArrayToFilterFieldOptionArray(urlParamValue as string[]);
                    if (tags) {
                        options = tags;
                    }
                    break;
                case 'boolean':
                    options = [{ displayName: filterDisplayName, value: urlParamValue == 'true' }] as FilterFieldOptionBoolean[];
                    break;
                case 'ternary':
                    let boolValue = urlParamValue == 'true';
                    let displayName = boolValue ? filterDisplayName : `Not ${filterDisplayName}`;
                    options = [ { displayName, value: boolValue }];
                    break;
            }
        }

        // add the formatted details to urlFilters
        urlFilters.value[filterFieldName as FilterFieldName] = {
            ...filters[filterFieldName],
            options
        }
    }));

    // update selectedFilters
    selectedFilters.value = {
        ...selectedFilters.value,
        ...urlFilters.value,
    }
    savedFilterKey.value++;
    context.emit('updateFilters', selectedFilters.value);

    return {
        urlSavedFilterId,
        urlFilters,
    }
}

export function useVehicleListingFilters({ context, vehicleStatuses, loggedInUserPersonId, isInModal}: {
    context: SetupContext<('updateFilters')[]>,
    vehicleStatuses?: VehicleStatus[],
    loggedInUserPersonId?: number,
    isInModal?: boolean,
}) {
    onMounted(() => {
        if (selectedFilters.value && Object.keys(selectedFilters.value).length) {
            context.emit('updateFilters', selectedFilters.value);
        }
    });

    const selectedFilters: Ref<SelectedFilters | undefined> = ref(undefined);

    // get all filters for vehicle listings
    const { 
        filters, 
        dependentFields,
        basicFilters,
        additionalFilters,
    }: { 
        filters: Filter, 
        dependentFields: ComputedRef<Filter>, 
        basicFilters: ComputedRef<Partial<Filter>>, 
        additionalFilters: ComputedRef<Partial<Filter>>,
    } = getVehicleListingFilters();
    
    // get user's saved filters
    const { 
        savedFilterOptions,
        selectedSavedFilterId,
        selectedSavedFilterOption,
        savedFilterKey,
        updateSelectedSavedFilter,
        saveNewFilter,
        saveUpdatedFilter,
    } = useSavedFilters({
        filters, 
        dependentFields: dependentFields.value,
        selectedFilters,
        context,
        isInModal
    });

    // get filters from URL Params
    useGetFiltersFromUrlParams({ 
        savedFilterKey,
        selectedSavedFilterId, 
        selectedFilters, 
        filterFieldNames: [...Object.keys(filters), Object.keys(dependentFields)] as FilterFieldName[],
        filters,
        context,
    });

    // get filter field options on Focus
    const loadingOptionsForFieldName: Ref<FilterFieldName | null> = ref(null);
    async function getVehicleListingFilterFieldOptions(fieldName: FilterFieldName) {
        if (!filters[fieldName]) {
            return;
        }

        loadingOptionsForFieldName.value = fieldName;

        await getFilterFieldOptions({
            fieldName,
            selectedFilters: omitFilterFields({ 
                filters: selectedFilters.value as Filter, 
                fieldsToOmit: [fieldName]
            }),
            vehicleStatuses,
            loggedInPersonId: loggedInUserPersonId,
            config: {
                dontFetchForFields: ['year', 'distance', 'price', 'mileage'],
                transformResults: ['sellerType'].includes(fieldName) ? filterOutResultsWithCountZero : undefined,
            }
        }).then(listings => {
                loadingOptionsForFieldName.value = null;
                if (listings) {
                    filters[fieldName]!.options = listings;
                }
            }).catch(error => {
                loadingOptionsForFieldName.value = null;
                openErrorDialog({
                    title: 'Failed to get filter options',
                    message: `We encountered an error while fetching filter options for field ${fieldName}. If the problem persists, contact support.`,
                    error,
                });
            });

    }

    return {
        filters,
        basicFilters,
        additionalFilters,
        dependentFields,
        selectedFilters,
        savedFilterOptions,
        selectedSavedFilterId,
        selectedSavedFilterOption,
        savedFilterKey,
        getVehicleListingFilterFieldOptions,
        loadingOptionsForFieldName,
        updateSelectedSavedFilter,
        saveNewFilter,
        saveUpdatedFilter,
    }
}


function getVehicleListingFilters() {
    const tagConfig: FilterFieldConfig = { filterType: 'tag' };

    const basicFilterFields: FilterFieldName[] = [
        'year', 
        'make',
        'model',
        'mileage',
        'price',
        'distance',
        'sellerType', 
        'sellerStore',
        'isInoperable', 
        'isWholesale',
        'isFrontline',
        'watchlist',
    ];
    const basicFilters = computed(() => pick(filters, basicFilterFields));
    const additionalFilterFields = computed(() => Object.keys(filters).filter(filterFieldName => !basicFilterFields.includes(filterFieldName as FilterFieldName)));
    const additionalFilters = computed(() => pick(filters, additionalFilterFields.value));

    const filters: Filter = reactive({
        sellerType: {
            displayName: 'Seller Type',
            field: 'sellerType',
            config: tagConfig,
            options: [],
        },
        sellerStore: {
            displayName: 'Seller Store',
            field: 'sellerStore',
            config: tagConfig,
            options: [],
        },
        year: {
            displayName: 'Year',
            field: 'year',
            config: {
                filterType: 'range',
                mask: 'date',
                rangeIncrement: 1
            },
            options: [
                { displayName: 'min', value: 1986 }, 
                { displayName: 'max', value: 2025 }
            ],
            customFormatter: (val: number) => val.toString(),
        },
        make: {
            displayName: 'Make',
            field: 'make',
            config: tagConfig,
            options: [],
        },
        model: {
            displayName: 'Model',
            field: 'model',
            config: tagConfig,
            options: [],
        },
        bodyType: {
            displayName: 'Body Type',
            field: 'bodyType',
            config: tagConfig,
            options: [],
        },
        trim: {
            displayName: 'Trim',
            field: 'trim',
            config: tagConfig,
            options: [],
        },
        distance: {
            displayName: 'Distance',
            field: 'distance',
            config: {
                filterType: 'range',
                rangeIncrement: 10,
                mask: 'localeString',
                dependentFields: [
                    {
                        displayName: 'Zip Code',
                        field: 'zip',
                        options: [{
                            displayName: 'zip',
                            value: undefined,
                        }],
                        config: {
                            filterType: 'string',
                        }
                    }
                ]
            },
            options: [
                { displayName: 'min', value: 0 }, 
                { displayName: 'max', value: 1000 }
            ], 
        },
        price: {
            displayName: 'Price',
            field: 'price',
            config: {
                filterType: 'range',
                rangeIncrement: 100,
                mask: 'currency',
            },
            options: [
                { displayName: 'min', value: 0 }, 
                { displayName: 'max', value: 100000 }
            ],
        },
        mileage: {
            displayName: 'Miles',
            field: 'mileage',
            config: {
                filterType: 'range',
                rangeIncrement: 1,
                mask: 'localeString',
            },
            options: [
                { displayName: 'min', value: 0 },
                { displayName: 'max', value: 200000 },
            ],
        },
        fuelType: {
            displayName: 'Fuel Type',
            field: 'fuelType',
            config: tagConfig,
            options: [],
        },
        carmigoInspected: {
            displayName: 'Carmigo Inspected',
            field: 'carmigoInspected',
            options: [],
            config: {
                filterType: 'boolean',
            }
        },
        isInoperable: {
            displayName: 'Inoperable',
            field: 'isInoperable',
            options: [],
            config: {
                filterType: 'ternary'
            },
        },
        isWholesale: {
            displayName: 'Fresh Trade',
            field: 'isWholesale',
            options: [],
            config: {
                filterType: 'ternary',
            },
        },
        isFrontline: {
            displayName: 'Frontline',
            field: 'isFrontline',
            options: [],
            config: {
                filterType: 'ternary',
            },
        },
        watchlist: {
            displayName: 'Watching',
            field: 'watchlist',
            options: [],
            config: {
                filterType: 'boolean',
            }
        },
    });

    const dependentFields: ComputedRef<Filter> = computed(() => {
        const dependentFilterFields: Filter = {};
        Object.keys(filters).forEach(filterField => {
            let filter = filters[filterField as FilterFieldName];
            filter?.config?.dependentFields?.forEach((dependentField) => {
                dependentFilterFields[dependentField.field] = dependentField;
            });
        });
        return dependentFilterFields
    });

    return {
        filters, 
        dependentFields, 
        basicFilters,
        additionalFilters,
    }
}


export function useSavedFilters({ filters, dependentFields, selectedFilters, context, isInModal }: {
    filters: Filter, 
    dependentFields: Filter,
    selectedFilters: Ref<SelectedFilters | undefined>,
    context: SetupContext<('updateFilters')[]>,
    isInModal?: boolean,
}) {
    const savedFilterOptions: Ref<SavedFilter[]> = ref([]);
    const selectedSavedFilterId: Ref<number | undefined> = ref(undefined);
    const savedFilterKey: Ref<number> = ref(0);

    const selectedSavedFilterOption = computed(() => {
        if (!savedFilterOptions.value?.length || !selectedSavedFilterId.value) {
            return undefined;
        }
        return savedFilterOptions.value.find(option => option.id == selectedSavedFilterId.value);
    });

    onBeforeMount(async() => {
        await getSavedFilters();
    });

    async function getSavedFilters() {
        let savedFilters = await GET(`/filters/getUserSavedFilters`).then(res => res.data);

        // for each savedFilter, convert to a SelectedFilters type
      savedFilterOptions.value = savedFilters.map((userSavedFilterDTO: UserSavedFilterDTO) => {
            return convertUserSavedFilterToSavedFilter(userSavedFilterDTO, filters, dependentFields);
        });
    }

    // detect changes to selected SavedFilter
    watch(() => selectedSavedFilterId.value, () => {
        let selected = savedFilterOptions.value.find(value => value.id == selectedSavedFilterId.value);
        if (selected) {
            updateSelectedSavedFilter(selected);
        }
    });

    watch(() => savedFilterOptions.value, () => {
        let selected = savedFilterOptions.value.find(value => value.id == selectedSavedFilterId.value);
        if (selected) {
            updateSelectedSavedFilter(selected);
        }
    });

    const route = useRoute();
    function updateSelectedSavedFilter(selectedSavedFilter?: SavedFilter) {
        selectedSavedFilterId.value = selectedSavedFilter?.id;
        selectedFilters.value = selectedSavedFilter ? JSON.parse(JSON.stringify(selectedSavedFilter?.filter)) : {};
        
        if (!isInModal) {
            updateFilterUrlParams({
                route, 
                selectedOptions: selectedSavedFilter?.filter,
                selectedSavedFilterId: selectedSavedFilterId.value,
                maintainParams: ['listingType']
            });
        }
        context.emit('updateFilters', selectedFilters.value);
        savedFilterKey.value++;
    }

    async function saveNewFilter(filterName: string, notifications: { email: boolean, text: boolean }) {
        if (!selectedFilters.value) {
            return;
        }
        const userSavedFilterDTO = convertSelectedFiltersToUserSavedFilterDTO(filterName, selectedFilters.value);
        let notificationSettings: NotificationSetting[] = normalizeFilterNotifications(notifications);
        return await POST(`/filters/saveUserFilter`, { filter: userSavedFilterDTO, notificationSettings })
            .then(async (res) => {
                let newFilterId = res.data.filterId;
                await getSavedFilters();
                selectedSavedFilterId.value = newFilterId;
                savedFilterKey.value++;
                return newFilterId;
            }).catch(error => {
                openErrorDialog({
                    title: 'Failed to save filter',
                    message: `We encountered an error while saving your filter preset`,
                    error,
                    displayErrorInDialog: true,
                });
            });
    }

    async function saveUpdatedFilter(filterId: number, filterName: string) {
        if (!selectedFilters.value) {
            return;
        }
        const userSavedFilterDTO = convertSelectedFiltersToUserSavedFilterDTO(filterName, selectedFilters.value);
        await POST(`/filters/updateUserSavedFilter`, {
            id: filterId, 
            ...userSavedFilterDTO
        }).then(res => {
            getSavedFilters();
        }).catch(error => {
            openErrorDialog({
                title: 'Failed to update saved filter',
                message: `We encountered an error while saving your changes to preset ${filterId}`,
                error,
                displayErrorInDialog: true,
            });
        });
    }

    return {
        savedFilterOptions,
        selectedSavedFilterId,
        selectedSavedFilterOption,
        savedFilterKey,
        updateSelectedSavedFilter,
        saveNewFilter,
        saveUpdatedFilter,
    }
}

export function useUpdateFilterSelectedOptions({ route, selectedFilters, context, isInModal }: {
    route?: Route,
    selectedFilters: SelectedFilters,
    context: SetupContext<('focus' | 'updateFilters' | 'selectSavedFilter' | 'removeSelectedSavedFilter' | 'saveUpdatedFilter' | 'saveNewFilter')[]>,
    isInModal?: Boolean,
}) {
    const selectedOptions = ref(selectedFilters);
    const selectedFiltersKey = ref(0);
    const removeFiltersKey = ref(0);
    
    if (!route) {
        route = useRoute();
    }

    function emitUpdateFilters() {
      context.emit('updateFilters', selectedOptions.value);
        if (!isInModal) {
            updateFilterUrlParams({
                route: route!, 
                selectedOptions: selectedOptions.value
            });
        }
        selectedFiltersKey.value++;
    }

    function applySingleFilter({ field, type, value, config }: { field: FilterFieldName, type: FilterInputType, value: FilterFieldOption | FilterFieldOption[], config: FilterFieldConfig }) {
        if (!selectedOptions.value[field]) {
            selectedOptions.value[field] = {};
        }
        if (!selectedOptions.value[field]!.options) {
            selectedOptions.value[field]!.options = [];
        }
        switch (type) {
            case 'tag':
                selectedOptions.value[field]!.options!.push(value as FilterFieldOption);
                break;
            case 'string':
            case 'number':
            case 'ternary':
                selectedOptions.value[field]!.options = [value as FilterFieldOption];
                break;
            case 'boolean':
                selectedOptions.value[field]!.options = (value as FilterFieldOption).value 
                    ? [value as FilterFieldOption]
                    : undefined;
                break;
            case 'range':
            default:
                if (config.mask) {
                    value = (value as FilterFieldOption[]).map(option =>{
                        return {
                            ...option,
                            value: option.value ? removeInputMask(config.mask!, option.value) : 0
                        }
                    });
                }
                selectedOptions.value[field]!.options = value as FilterFieldOption[];
                break;
        }
        emitUpdateFilters();
    }

    function removeSingleFilter({ field, value, filterType }: {
        field: FilterFieldName,
        value: FilterFieldOption,
        filterType?: FilterInputType,
    }) {
        let currentFilters = selectedOptions.value[field];
        if (!currentFilters || !currentFilters.options) {
            return;
        }
        if (filterType == 'range') {
            selectedOptions.value[field]!.options = undefined;
        } else if (filterType == 'ternary') {
            delete selectedOptions.value[field];
        } else {
            selectedOptions.value[field]!.options = currentFilters.options.filter((selectedFilter: FilterFieldOption) => {
                return selectedFilter.displayName !== value.displayName
            });
        }
        emitUpdateFilters();
        removeFiltersKey.value++;
    }

    function removeAllFilters() {
        selectedOptions.value = {};
        emitUpdateFilters();
        context.emit('removeSelectedSavedFilter');
        removeFiltersKey.value++;
    }

    return {
        selectedOptions,
        applySingleFilter,
        removeSingleFilter,
        removeAllFilters,
        selectedFiltersKey,
        removeFiltersKey,
    }
}

export function useAppFiltersSelected({ filters, selectedSavedFilter, selectedFilters}: {
    filters: Filter,
    selectedSavedFilter: SavedFilter,
    selectedFilters: SelectedFilters,
}) {
    const hoveredFilterFieldName: Ref<FilterFieldName | null> = ref(null);
    
    const configs = getFilterFieldConfigs(selectedFilters, filters);

    const hasChanges: ComputedRef<boolean> = computed(() => {
        return selectedSavedFilter
            ? checkForSavedFilterChanges(selectedSavedFilter.filter, selectedFilters)
            : true;
    });

    const selectedFiltersFormatted: ComputedRef<SelectedFilter[]> = computed(() => formatFilters());

    // e.g., format years as '(min) - (max)' and separate filter options
    function formatFilters(): SelectedFilter[] {
        let nonEmptyFilters = getNonEmptySelectedOptionValues(selectedFilters);

        let filterParametersFiltered: SelectedFilter[] = [];

        Object.keys(nonEmptyFilters).forEach((filterField) => {
            let filterFieldName = filterField as FilterFieldName;
            const selectedFilter = selectedFilters[filterFieldName];
            let filterFieldConfig = selectedFilter?.config ?? configs[filterFieldName];
            const selectedFiltersForField = selectedFilter?.options;

            if (!selectedFiltersForField || !filterFieldConfig) {
                return;
            }

            if (filterFieldConfig?.filterType == 'range') {
                let minValue = applyFilterMask(selectedFiltersForField[0].value, filterFieldConfig?.mask)
                let maxValue = applyFilterMask(selectedFiltersForField[1].value, filterFieldConfig?.mask)
                const processedFilter = {
                    field: filterFieldName,
                    displayValue: filterFieldName === 'year'
                        ? `${minValue} - ${maxValue}`
                        : `${minValue.toLocaleString()} - ${maxValue.toLocaleString()}`,
                    filter: selectedFiltersForField,
                    filterType: filterFieldConfig.filterType,
                };
                filterParametersFiltered.push(processedFilter);
            } else {
                // otherwise display all of them separately
                selectedFiltersForField?.forEach((filter: FilterFieldOption) => {
                    const processedFilter = {
                        field: filterFieldName,
                        displayValue: filter.displayName,
                        filterType: filterFieldConfig!.filterType,
                        filter,
                    };

                    filterParametersFiltered.push(processedFilter);
                });
            }
        });
        return filterParametersFiltered;
    }

    return {
        selectedFiltersFormatted,
        hoveredFilterFieldName,
        hasChanges,
        configs,
    }

}
