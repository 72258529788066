
import { PropType, defineComponent } from 'vue';

interface Resolution {
  width: number;
  height: number;
}

export default defineComponent({
    name: 'AppCamera',
    emits: ['loading', 'error', 'started', 'stopped', 'snapshot', 'close'],
    props: {
        resolution: {
            type: Object as PropType<Resolution>,
            default: () => ({ width: 1920, height: 1080 }),
        },
        facingMode: {
            type: String,
            default: 'environment',
        },
        autoplay: {
            type: Boolean,
            default: true,
        },
        playsinline: {
            type: Boolean,
            default: true,
        },
        constraints: {
            type: Object,
            required: false,
        },
        landscapeOnly: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        stream: null as null | MediaStream,
        video: null as null | HTMLVideoElement,
        canvas: null as null | HTMLCanvasElement,
        isLandscape: false,
    }),
    mounted() {
        if (!navigator.mediaDevices) {
            throw new Error('Media devices not available');
        }

        this.video = this.$refs.video as HTMLVideoElement;
        this.canvas = this.$refs.canvas as HTMLCanvasElement;

        if (this.playsinline && this.video) {
            this.video.setAttribute('playsinline', '');
        }

        if (this.autoplay) {
            this.start();
        }

        if (this.landscapeOnly) {
            this.startOrientationChangeListener();
        }
    },
    methods: {
        async start() {
            this.$emit('loading');

            const constraints = this.constraints || {
                video: {
                    width: this.resolution.width,
                    height: this.resolution.height,
                    facingMode: this.facingMode,
                    deviceId: {},
                },
                audio: false,
            };

            try {
                this.stream = await navigator.mediaDevices.getUserMedia(constraints);

                if (!this.video) {
                    throw new Error('Video is not yet ready.');
                }

                this.video.srcObject = this.stream;

                this.$emit('started');
            } catch (error) {
                this.$emit('error', error);
            }
        },

        snapshot(
            resolution: Resolution,
            quality?: number,
            type = 'image/png',
        ) {
            if (!this.video) {
                throw new Error('Video is not yet ready.');
            }

            if (!this.canvas) {
                throw new Error('Canvas is not yet ready.');
            }

            const { width, height } = resolution || this.resolution;

            this.canvas.width = width;
            this.canvas.height = height;

            this.canvas.getContext('2d')?.drawImage(this.video, 0, 0, width, height);

            return new Promise((resolve) => {
                this.canvas?.toBlob(
                    (blob: Blob | null) => {
                        this.$emit('snapshot', blob);
                        resolve(blob);
                    },
                    type,
                    quality,
                );
            });
        },

        async startOrientationChangeListener() {
            const landscape = window.matchMedia('(orientation: landscape)');

            this.isLandscape = landscape.matches;

            landscape.addEventListener('change', this.orientationChangeListener);
        },

        orientationChangeListener(e: MediaQueryListEvent) {
            this.isLandscape = e.matches;
        },

        stop() {
            this.stream?.getTracks().forEach((track) => track.stop());
            this.$emit('stopped');
        },
    },
    beforeDestroy() {
        window.matchMedia('(orientation: landscape)').removeEventListener('change', this.orientationChangeListener);
    },
});
