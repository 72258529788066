var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-field',_vm._b({},'b-field',_vm.$attrs.fieldAttrs,false),[_c('b-input',_vm._b({on:{"iconRightClick":function($event){_vm.isClearable ? _vm.$emit('input', undefined) : ''},"input":function($event){return _vm.$emit('input', $event)}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('input', _vm.valueUpdated)}},model:{value:(_vm.valueUpdated),callback:function ($$v) {_vm.valueUpdated=$$v},expression:"valueUpdated"}},'b-input',{
            type: _vm.getType,
            passwordReveal: _vm.type == 'password',
            iconRight: _vm.isClearable ? 'close-circle' : '',
            iconRightClickable: _vm.isClearable,
            ..._vm.$attrs,
        },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }