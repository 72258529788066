import { render, staticRenderFns } from "./AppListingDetails.vue?vue&type=template&id=31d1718a&scoped=true&"
import script from "./AppListingDetails.vue?vue&type=script&lang=ts&"
export * from "./AppListingDetails.vue?vue&type=script&lang=ts&"
import style0 from "./AppListingDetails.vue?vue&type=style&index=0&id=31d1718a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31d1718a",
  null
  
)

export default component.exports