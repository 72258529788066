
import Vue, { defineComponent, onMounted, PropType } from 'vue';
import { insertHyperlinkElement } from '../utils';

type AnnouncementType = 'positive' | 'negative' | 'neutral';

export default defineComponent({
    name: 'TheAnnouncement',
    props: {
        announcement: {
            type: String,
            required: true,
        },
        announcementType: {
            type: String as PropType<AnnouncementType>,
            required: true,
        },
        href: {
            type: String,
            required: false,
        }
    },
    setup(props, { emit, attrs }) {
        onMounted(() => {
            Vue.nextTick(() => {
                if (props.href) {
                    insertHyperlinkElement({
                        querySelector: `#comment-with-link-${attrs.id}`,
                        displayText: props.announcement,
                        href: props.href,
                    });
                }
            })
        });

        function getIconColorByAnnouncementType(announcementType: AnnouncementType) {
            switch (announcementType) {
                case 'positive':
                    return 'has-text-primary';
                case 'negative':
                    return 'has-text-danger';
                case 'neutral':
                default:
                    return 'has-text-dark';
            }
        }
        return {
            getIconColorByAnnouncementType,
        }
    },
});
