import { POST } from "@/api";
import { fireEvent } from "@/segment";
import { InspectionUpdateAnnouncements } from "@/types";
import { openErrorDialog } from "@/utils";
import { reject } from "lodash";

/**
 * Given the ID of a vehicle from a dealer's inventory, creates the necessary records for a vehicle_listing (including vehicle, auction_builder, etc)
 * @param inventoryId 
 * @returns ID of the newly created vehicle_listing
 */
export async function createVehicleListingFromDealerInventory({ inventoryId, pricing, announcements, isMarketplaceListing, vehicleListingId, marketplaceListingId }: {
    inventoryId: number,
    pricing: {
        startingPrice: number,
        reservePrice: number,
        buyItNowPrice?: number,
        canBuyItNow: boolean
    },
    announcements: InspectionUpdateAnnouncements,
    isMarketplaceListing: boolean,
    marketplaceListingId?: number,
    vehicleListingId?: number,
}): Promise<{ vehicleListingId: number, marketplaceListingId: number }> {
  return await POST(`/dealerInventory/createListing`, { 
    inventoryId, 
    pricing, 
    announcements, 
    marketplaceListing: isMarketplaceListing,
    marketplaceListingId,
    vehicleListingId,
  }).then(newVehicleListingId => {
    fireEvent('Listing created via inventory integration');
    return newVehicleListingId.data;
  }).catch(error => {
    console.error('Error in createListing', error);
    openErrorDialog({
      message: `We encountered an error while creating a listing for inventory ${inventoryId}`,
      error,
    });
    reject(error);
  });
}
