
import { defineComponent, PropType, ref, watch } from 'vue';
import { useEditableFields } from '../composables';
import { InspectionConditionsDTO } from '../types';
import { InfoRow } from './AppInfoRows.vue';

import EditableField from './Forms/EditableField.vue';
import TheListingDetailsTireTread from './TheListingDetailsTireTread.vue';

export default defineComponent({
    name: 'TheListingDetailsTireTreadEditable',
    props: {
        tireTreadMeasurements: {
            type: Array as PropType<InfoRow[]>,
            required: false,
        },
        inspectionDTO: {
            type: Object as PropType<InspectionConditionsDTO>,
            required: false,
        },
        isEditingMode: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        EditableField,
        TheListingDetailsTireTread,
    },
    emits: ['input'],
    setup(props, context) {
        const updatedTireTreadMeasurements = ref(props.tireTreadMeasurements);
        if (props.inspectionDTO) {
            useTireMeasurementsFromInspectionDTO();
        }

        function useTireMeasurementsFromInspectionDTO() {
            if (props.inspectionDTO) {
                const { frontDriverValue, frontPassengerValue, rearDriverValue, rearPassengerValue } = props.inspectionDTO;
                updatedTireTreadMeasurements.value = [
                    { name: 'Front Driver', value: frontDriverValue },
                    { name: 'Front Passenger', value: frontPassengerValue },
                    { name: 'Rear Driver', value: rearDriverValue },
                    { name: 'Rear Passenger', value: rearPassengerValue },
                ]
            }
        }


        watch(() => props.inspectionDTO, () => {
            useTireMeasurementsFromInspectionDTO();
        });

        function updateTireTread(value: string, idx: number) {
            updatedTireTreadMeasurements.value[idx].value = value;
        }

        const { emitUpdates } = useEditableFields({ context });

        function emitTireTreadMeasurements() {
            emitUpdates({
                frontDriverValue: updatedTireTreadMeasurements.value[0].value,
                frontPassengerValue: updatedTireTreadMeasurements.value[1].value,
                rearDriverValue: updatedTireTreadMeasurements.value[2].value,
                rearPassengerValue: updatedTireTreadMeasurements.value[3].value,
            });
        }

        return {
            updatedTireTreadMeasurements,
            updateTireTread,
            emitTireTreadMeasurements,
        }
    },
});
