
import { computed, defineComponent, PropType, ref } from 'vue';

export default defineComponent({
    name: 'AppInputFieldString',
    inheritAttrs: false,
    props: {
        type: {
            type: String as PropType<'password' | 'textarea' | 'string'>,
            default: 'string',
        },
        value: {
            type: String,
            required: false,
        },
        isClearable: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        
    },
    emits: ['input'],
    setup(props, { emit, attrs }) {
        const valueUpdated = ref(props.value);
        const getType = computed(() => {
            switch (props.type) {
                case 'string':
                case 'password':
                    return 'text';
                case 'textarea':
                    return 'textarea';
                default: 
                    return props.type;

            }
        });

        return {
            getType,
            valueUpdated,
        }
    },
});
