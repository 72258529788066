<template>
    <b-field :class="{ 
        'is-edited' : isEdited,
        'is-missing': isMissing,
    }">
        <b-dropdown
            v-model="selectedOption"
            v-bind="$attrs"
            @input="value => $emit('input', { [label]: value})"
        >
            <template #trigger="{ active }">
                <slot name="dropdownTrigger" v-bind:active="active">
                    <b-button
                        class="dropdown-trigger"
                        v-bind="$attrs"
                        :icon-right="active ? 'menu-up' : 'menu-down'"
                        outlined
                        expanded
                    >
                        {{ selectedOption ? selectedOption : 'Select' }}
                    </b-button>
                </slot>
            </template>

            <b-dropdown-item
                aria-role="listitem"
                v-for="option, idx in options"
                :key="`dropdown-item-${idx}`"
                :value="option"
            >
                <slot name="dropdownItem" v-bind:option="option">
                    {{ option }}
                </slot>
            </b-dropdown-item>
        </b-dropdown>
    </b-field>
</template>

<script>
export default {
    inheritAttrs: false,
    name: 'EditableDropdown',
    props: {
        options: {
            type: Array,
            required: true,
        },
        value: {
            type: String,
            required: false,
        },
        label: {
            type: String,
            required: true,
        },
        isMissing: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            selectedOption: this.value,
        }
    },
    computed: {
        isEdited() {
            return this.selectedOption != this.value;
        }
    },
}
</script>

<style scoped lang='postcss'>
.dropdown-trigger {
    border-color: hsl(0, 0%, 86%);
    color: hsl(0, 0%, 21%);
}

.is-edited {
    & .dropdown-trigger {
        color: var(--primary);
        border-color: currentColor;
    }
}
.is-missing {
    & .dropdown-trigger {
        border-color: var(--danger);
    }
}
</style>