
import { computed, ComputedRef, defineComponent, PropType, Ref, ref } from 'vue';
import { InspectionConditionsDTO } from '../types/InpectionDTO';

import AppInfoRows, { InfoRow } from './AppInfoRows.vue';
import TheListingDetailsCard from './TheListingDetailsCard.vue';

export default defineComponent({
    name: 'TheListingDetailsTireTread',
    props: {
        tireTreadMeasurements: {
            type: Array as PropType<InfoRow[]>,
            required: false,
        },
        inspectionDTO: {
            type: Object as PropType<InspectionConditionsDTO>,
            required: false,
        }
    },
    components: {
        AppInfoRows,
        TheListingDetailsCard,
    },
    setup(props, { emit, attrs }) {
        const tireTreadMeasurementsFromInspectionDTO: ComputedRef<InfoRow[] | undefined> = computed(() => {
            if (props.tireTreadMeasurements) {
                return props.tireTreadMeasurements;
            }

            if (props.inspectionDTO) {
                const { frontDriverValue, frontPassengerValue, rearDriverValue, rearPassengerValue } = props.inspectionDTO;
                let tireTread  = [
                    { name: 'Front Driver', value: frontDriverValue },
                    { name: 'Front Passenger', value: frontPassengerValue },
                    { name: 'Rear Driver', value: rearDriverValue },
                    { name: 'Rear Passenger', value: rearPassengerValue },
                ];
                return tireTread;
            }
        });
        
        return {
            tireTreadMeasurementsFromInspectionDTO,
        }
    },
});
