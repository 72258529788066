
import { defineComponent, PropType, ref  } from 'vue';
import { useBreakpoint, useEditableFields } from '../composables';
import { AdditionalPricingDTO, ImageDTO, InspectionConditionsDTO, SRPListing, TitleStatus, VehicleListingAnnouncement, VehiclePhotosDTO } from '../types'; 
import { BBVehicleFeature } from '../types/bbVehicleFeature';
import { convertVehiclePhotosDtoToImageDtoArray } from '../utils';

import AppButton from '../components/AppButton.vue';
import AppListingDetails from '../components/AppListingDetails.vue';
import TheEditListingPhotosButton from '../components/TheEditListingPhotosButton.vue';
import TheListingDetailsAnnouncementsEditable from '../components/TheListingDetailsAnnouncementsEditable.vue';
import TheListingDetailsBookoutOptionsEditable from '../components/TheListingDetailsBookoutOptionsEditable.vue';
import TheListingDetailsPricingEditable from '../components/TheListingDetailsPricingEditable.vue';
import TheListingDetailsSellerDetailsEditable from '../components/TheListingDetailsSellerDetailsEditable.vue';
import TheListingDetailsTireTreadEditable from '../components/TheListingDetailsTireTreadEditable.vue';
import TheListingDetailsVehicleAttributesEditable from '../components/TheListingDetailsVehicleAttributesEditable.vue';
import TheListingDetailsVehicleBasicsEditable from '../components/TheListingDetailsVehicleBasicsEditable.vue';

export default defineComponent({
    name: 'AppListingDetailsEditable',
    props: {
        isEditingMode: {
            type: Boolean,
            default: true,
        },
        listing: {
            type: Object as PropType<SRPListing>,
            required: true,
        },
        vehicleListingId: {
            type: Number,
            required: true,
        },
        marketplaceListingId: {
            type: Number,
            required: false,
        },
        editableSections: {
            type: Array as PropType<String[]>,
            required: false, // if undefined, all sections are editable
        },
        vehiclePhotos: {
            type: Array as PropType<ImageDTO[]>,
            required: false,
        },
        vehiclePhotosDTO: {
            type: Object as PropType<VehiclePhotosDTO>,
            required: false,
        },
        announcements: {
            type: Array as PropType<VehicleListingAnnouncement[]>,
            required: false,
        },
        bookoutOptions: {
            type: Array as PropType<BBVehicleFeature[]>,
            required: false,
        },
        tireTread: {
            type: Object as PropType<InspectionConditionsDTO>,
            required: false,
        },
        titleStatus: {
            type: String as PropType<TitleStatus>,
            required: false,
        },
        sellerReason: {
            type: String,
            required: false,
        },
        additionalPricingDetails: {
            type: Object as PropType<AdditionalPricingDTO>,
            required: false,
        },
    },
    components: {
        AppButton,
        AppListingDetails,
        TheEditListingPhotosButton,
        TheListingDetailsAnnouncementsEditable,
        TheListingDetailsBookoutOptionsEditable,
        TheListingDetailsPricingEditable,
        TheListingDetailsSellerDetailsEditable,
        TheListingDetailsTireTreadEditable,
        TheListingDetailsVehicleAttributesEditable,
        TheListingDetailsVehicleBasicsEditable,
    },
    emits: ['input', 'updateVehiclePhotos', 'addAnnouncement'],
    setup(props, context) {
        const vehicleKey = ref(0);

        function isSectionEditable(sectionName: string) {
            if (!props.editableSections) {
                return true;
            }
            return props.editableSections.includes(sectionName);
        }

        const { emitUpdates } = useEditableFields({ context });
        const { windowWidth } = useBreakpoint();

        function updatePhotosAfterSaving(event: VehiclePhotosDTO) {
            context.emit('updateVehiclePhotos', {
                vehiclePhotos: convertVehiclePhotosDtoToImageDtoArray(event),
                vehiclePhotosDTO: event,
            });
        }

        return {
            isSectionEditable,
            updatePhotosAfterSaving,
            emitUpdates,
            windowWidth,
            vehicleKey,
        }
    }
});
