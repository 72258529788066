
import { defineComponent, PropType } from 'vue';
import { VehicleListingAnnouncement } from '../types';

import TheAnnouncement from './TheAnnouncement.vue';
import TheListingDetailsCard from './TheListingDetailsCard.vue';

type AnnouncementType = 'positive' | 'negative' | 'neutral';

export default defineComponent({
    name: 'TheListingDetailsAnnouncements',
    props: {
        announcements: {
            type: Array as PropType<VehicleListingAnnouncement[]>,
            default: () => { return [] },
        }
    },
    components: {
        TheAnnouncement,
        TheListingDetailsCard,
    },
    setup(props, { emit, attrs }) {
        function getIconColorByAnnouncementType(announcementType: AnnouncementType) {
            switch (announcementType) {
                case 'positive':
                    return 'has-text-primary';
                case 'negative':
                    return 'has-text-danger';
                case 'neutral':
                default:
                    return 'has-text-dark';
            }
        }
        return {
            getIconColorByAnnouncementType,
        }
    },
});
