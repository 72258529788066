
import { defineComponent, PropType, ref } from 'vue';

import AppButton from './AppButton.vue';

export default defineComponent({
    name: 'AppDropdown',
    props: {
        options: {
            type: Array,
            default: [],
        },
        optionLabelKey: {
            type: String,
            required: false,
        },
        optionValueKey: {
            type: String,
            required: false,
        },
        placeholder: {
            type: String,
            required: false,
        },
        value: {
            type: [String, Array], // array for 'multiple' select
            required: false,
        }
    },
    components: {
        AppButton,
    },
    setup(props, { emit, attrs }) {
        const selectedOption = ref(props.value);

        return {
            selectedOption,
        }
    },
});
