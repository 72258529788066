
import { defineComponent, PropType, ref, Ref } from 'vue';
import { useKeyboardGalleryNavigation } from '../composables';
import { CarouselItem } from '../types';

import ButtonIcon from './ButtonIcon.vue';

export default defineComponent({
    name: 'AppCarousel',
    props: {
        items: {
            type: Array as PropType<CarouselItem[]>,
            required: true,
        },
        canExpandGallery: {
            type: Boolean,
            default: false,
        },
        allowKeyboardNavigation: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        ButtonIcon,
    },
    emits: ['toggleGalleryView', 'increment', 'change'],
    setup(props, { emit, attrs }) {
        const isGalleryView: Ref<boolean> = ref(false);

        function toggleGalleryView(value: boolean) {
            if (!props.canExpandGallery) {
                return;
            }
            isGalleryView.value = value;
            emit('toggleGalleryView', value);
            if (value) {
                document.documentElement.classList.add('is-clipped');
            } else {
                document.documentElement.classList.remove('is-clipped');
            }
        }

        if (props.allowKeyboardNavigation) {
            useKeyboardGalleryNavigation({ emit, onToggleGalleryView: toggleGalleryView });
        }

        return {
            isGalleryView,
            toggleGalleryView,
        }
    },
});
